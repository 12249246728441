import { useContext } from "react";
import { MatlibContext } from "../../contexts/MatlibContext";

function Like({ materialId, screenWidth }) {
  const { like, changeLike, removeLike } = useContext(MatlibContext);

  const handleChange = () => {
    const isLiked = (element) => element === materialId;

    if (like.find(isLiked)) {
      removeLike(materialId);
    } else {
      changeLike(materialId);
    }
  };

  const starSolid = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 576 512"
      stroke="currentColor"
    >
      <path
        fill="#FFFFFF"
        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
      ></path>
    </svg>
  );

  const starOutline = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 576 512"
      stroke="currentColor"
    >
      <path
        fill="#FFFFFF"
        d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"
      />
    </svg>
  );

  return (
    <div className="like" onClick={handleChange}>
      {/* {screenWidth !== "mobile" ?  <ScreenLike /> : <MobileLike />}  */}

      {screenWidth === "mobile" ? (
        <>{like.indexOf(materialId) !== -1 ? starSolid : starOutline}</>
      ) : (
        <>
          {like.indexOf(materialId) !== -1 ? (
            <p>remove from your collection</p>
          ) : (
            <p>add to your collection</p>
          )}
        </>
      )}
      {/* {like.indexOf(materialId) !== -1 ? <img src={StarSolid} alt="solid" width="20px" /> : <img src={StarOutline} alt="outline" width="20px"/>} */}
    </div>
  );
}

export default Like;
