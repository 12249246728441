import { useContext } from "react";
import { MatlibContext } from "../../contexts/MatlibContext";

const searchArray = [
  "kind leather",
  "nubuck",
  "cow hide",
  "suede",
  "Soft",
  "perf",
  "emboss",
  "fracture",
  "tectuff",
];
function QuickFilter({ isDark }) {
  const { fuzzySort, changeFuzzySort, triggerFilter, orderBy, changeOrderBy } =
    useContext(MatlibContext);
  return (
    <div
      className={`quick-filter ${triggerFilter ? "trigger-filter" : ""} ${
        isDark ? "dark" : ""
      }`}
    >
      <div className="trigger-header">Recent searches:</div>
      {searchArray.map((item) => (
        <button
          key={item}
          className={`${isDark ? "dark" : ""} ${
            fuzzySort === item && "active"
          }`}
          onClick={() => changeFuzzySort(item)}
        >
          {item}
        </button>
      ))}

      {fuzzySort !== "" && (
        <button
          className={`clear-search ${isDark ? "dark" : ""} active`}
          onClick={() => changeFuzzySort("")}
        >
          Clear Search
        </button>
      )}

      {fuzzySort === "" && (
        <>
          <div
            style={{
              width: "40px",
              borderRight: "thin solid #313131",
              height: "55%",
              marginRight: "20px",
            }}
          ></div>
          <div className="trigger-header">Filter By:</div>
          <button
            className={`${isDark ? "dark" : ""} ${
              orderBy === "modified" && "active"
            }`}
            onClick={() => changeOrderBy("modified")}
          >
            Recently Updated
          </button>
          <button
            className={`${isDark ? "dark" : ""} ${
              orderBy === "dateAdded" && "active"
            }`}
            onClick={() => changeOrderBy("dateAdded")}
          >
            Newest
          </button>
          <button
            className={`${isDark ? "dark" : ""} ${
              orderBy === "popular" && "active"
            }`}
            onClick={() => changeOrderBy("popular")}
          >
            Popular
          </button>
        </>
      )}
    </div>
  );
}

export default QuickFilter;
