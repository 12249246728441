import { useContext, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../../styles/gui.scss";

import Logo from "../../../images/matlibLogo.svg";
import DarkSwitch from "../DarkSwitch";
//import Search from '../Search';
import Menu from "../Menu";
//import { useHistory } from "react-router-dom";

import { UserContext } from "../../../contexts/UserContext";
//import { MatlibContext } from "../../../contexts/MatlibContext";
import Filter from "../Filter";

function Navbar() {
  const location = useLocation();
  //const history = useHistory();
  const { user, isMobile, isDark, changeIsDark } = useContext(UserContext);
  //const { like  } = useContext(MatlibContext)

  const [menuOpen, setMenuOpen] = useState(false);

  const theUser = user[0];

  const menuButton = useMemo(() => {
    const menuIcon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        viewBox="0 0 24 24"
        stroke="none"
      >
        <path d="M3,18h18v-2H3V18z M3,13h18v-2H3V13z M3,6v2h18V6H3z" />
      </svg>
    );

    return <div className={`menu-button ${isDark && "dark"}`}>{menuIcon}</div>;
  }, [isDark]);

  // function handleCollection() {
  //   history.push("/collection");
  // }

  // function handleLibrary() {
  //   history.push("/lib");
  // }

  return (
    <>
      {location.pathname !== "/" && (
        <div className={`navbar ${isDark ? "dark" : ""}`}>
          <Link to={"/lib"}>
            {" "}
            <img
              className="logo"
              src={Logo}
              alt="Matlib logo"
              height="26"
              width="100"
            />{" "}
          </Link>
          <div
            style={{
              width: "calc(100% - 50px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* {location.pathname === '/lib' &&  <Search isDark={isDark} isMobile={isMobile}/>} */}
            {location.pathname === "/test" && <Filter isDark={isDark} />}
          </div>

          <nav>
            <ul>
              {/* {location.pathname !== '/lib' &&  <li><Link to="/lib">Library</Link></li>} */}
              {/* {location.pathname !== '/lib' &&    <li onClick={handleLibrary}><div className={`collection-button ${isDark && "dark"}`}>Library</div></li>} */}

              {/* {location.pathname !== '/collection' && <li onClick={handleCollection}><div className={`collection-button ${isDark && 'dark'}`}>Your Collection <div className="collection-counter">{like.length}</div></div></li>} */}
              {theUser.userId && user ? null : (
                <li>
                  <Link to="/">Login</Link>
                </li>
              )}
              <li>
                {" "}
                <div
                  className="nav-menu-button"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  {menuButton}
                </div>
              </li>
              <li>
                <DarkSwitch isDark={isDark} changeIsDark={changeIsDark} />
              </li>
            </ul>
          </nav>
          <Menu
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            user={theUser}
            isMobile={isMobile}
            isDark={isDark}
            changeIsDark={changeIsDark}
          />
        </div>
      )}
    </>
  );
}

export default Navbar;
