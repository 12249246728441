import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../styles/components.scss";
import LazyLoad from "react-lazyload";
import Like from "./gui/Like";
import MaterialModal from "./MaterialModal";
import KindLeatherLogo from "./utils/KindLeatherLogo";
import { UserContext } from "../contexts/UserContext";
import { MatlibContext } from "../contexts/MatlibContext";
import { nikeLogo } from "../components/utils/Icons";

function Swatch({ material }) {
  const imageCount = material.gallery_swatch.length;
  const [viewing, setViewing] = useState(material.gallery_swatch[0]);
  const [imageIndex, setImageIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeHover, setActiveHover] = useState(false);

  const { user, isDark } = useContext(UserContext);
  const { storeNike } = useContext(MatlibContext);

  const handleChangeView = (e) => {
    e.preventDefault();
    setImageLoaded(false);
    setViewing(e.target.getAttribute("data-info"));
    setImageIndex(e.target.getAttribute("data-index"));
  };
  const handleLoaded = () => {
    setImageLoaded(true);
  };
  const handleMaterialModal = () => {
    setShowModal(true);
  };

  // useEffect(() => {
  //   const timer = setTimeout(
  //     () => setViewing(material.gallery_swatch[0]),
  //     20000
  //   );
  //   return () => clearTimeout(timer);
  // }, [viewing, material.gallery_swatch]);

  const priceGetter = () => {
    if (user[0].userRole === "vendor" && user[0].userName === "Jesse Smith") {
      return material.price;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => setActiveHover(false), 4000);
    return () => clearTimeout(timer);
  }, [activeHover]);

  function isNike(slug) {
    const findOut = storeNike.find((item) => item.slug === slug);
    //console.log(slug, !findOut ? "n" : "yes!");
    return !findOut ? false : true;
  }

  return (
    <>
      <div className={`swatch-card ${isDark ? "dark" : ""}`}>
        <div className="swatch-image">
          <LazyLoad offset={100} height={200} placeholder={"Loading Swatch..."}>
            <img
              src={viewing}
              onClick={handleMaterialModal}
              onLoad={handleLoaded}
              data-loading={imageLoaded}
              data-image={imageIndex}
              alt={`${material.title} by ${material.vendor} `}
              width="330"
              height="250"
            />
          </LazyLoad>

          <Link to={`/vendor/${material.vendor.toLowerCase()}`}>
            <div className="vendor-name"> {material.vendor}</div>
          </Link>
          <Like materialId={material.id} screenWidth={"mobile"} />
          {material.type === "cowhide" && <KindLeatherLogo />}
          {isNike(material.slug) && <div className="nike-logo">{nikeLogo}</div>}
          <div className="image-counter">
            {material.gallery_swatch.map((imgCnt, i) =>
              imageCount <= 1 ? (
                ""
              ) : (
                <button
                  key={material.id + i}
                  data-info={imgCnt}
                  data-index={i}
                  onClick={handleChangeView}
                  style={
                    viewing === imgCnt
                      ? { background: "transparent" }
                      : { background: "rgba(255,255,255,0.7)" }
                  }
                  disabled={viewing === imgCnt ? true : false}
                ></button>
              )
            )}
          </div>
        </div>

        {/* {!imageLoaded ? <LinearProgress color="secondary" style={{height:'2px', content:''}}/> : <div style={{height:'2px', content:''}}></div>}  */}
        <div className="title" onClick={handleMaterialModal}>
          <h4>{material.title} </h4>{" "}
        </div>

        <div
          className="description"
          data-count={material.view_count}
          onClick={handleMaterialModal}
        >
          <h6>
            {material.description} {priceGetter()}
          </h6>
        </div>
      </div>
      <MaterialModal
        id={material.id}
        materialTitle={material.title}
        materialDescription={material.description}
        materialSubstrate={material.substrate}
        materialVendor={material.vendor}
        imageIndex={imageIndex}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
}

export default Swatch;
