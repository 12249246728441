
function User() {
    return (
        <div>
            <div>User Managment</div>
        </div>
    )
}

export default User
