import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { useHistory, useLocation } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import "../../styles/gui.scss";

import { UserContext } from "../../contexts/UserContext";

function Login() {
  const history = useHistory();
  const location = useLocation();

  const { user, addUser, loggedIn, changeLoggedIn, isMobile, isDark } =
    useContext(UserContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);
  const [jumpName, setJumpName] = useState("");
  //const [ favorites, setFavorites ] = useState([])

  const query = new URLSearchParams(location.search);
  const mail = query.get("m");
  const name = query.get("n");

  useEffect(() => {
    if (mail) {
      let mailBuff = Buffer(mail, "base64");
      let mailString = mailBuff.toString("ascii");
      setEmail(mailString);
      let nameBuff = Buffer(name, "base64");
      let nameString = nameBuff.toString("ascii");
      setJumpName(nameString);
      handleJumpEmail(nameString, mailString);
    }
    // eslint-disable-next-line
  }, [mail]);

  const onFormSubmit = (event) => {
    event.preventDefault();
    const siteUrl = "https://matlib.co";
    setLoading(true);
    if (!loggedIn) {
      axios
        .post(
          `https://matlib.co/?rest_route=/simple-jwt-login/v1/auth&email=${email}&password=${password}`
        )
        .then((res) => {
          setToken(res.data.data.jwt);
          localStorage.setItem("token", res.data.data.jwt);
          if (res.data.data.jwt) {
            axios
              .get(
                `${siteUrl}/wp-json/ml/v1/user`,
                {
                  params: {
                    email: email,
                    login_user: email,
                  },
                },
                {
                  headers: {
                    Authorization: "Bearer " + token,
                  },
                }
              )
              .then((result) => {
                addUser(
                  result.data.id,
                  result.data.displayName,
                  result.data.email,
                  result.data.brand,
                  result.data.role
                );

                localStorage.setItem("userId", result.data.id);
                localStorage.setItem("userName", result.data.displayName);
                localStorage.setItem("userEmail", result.data.email);
                localStorage.setItem("userBrand", result.data.brand);
                localStorage.setItem("userRole", result.data.role);
                changeLoggedIn(true);
                handleEmail();
                setTimeout(() => {
                  history.push(`./lib`);
                }, 1000);
              })
              .catch((err) => {
                console.log(err);
              });
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
          setTimeout(() => setError(false), 3000);
        });
    }
  };

  const handleJumpEmail = (name, email) => {
    const subject = `Matlib - ${name} has transitioned`;
    const body = `<h1>${name} has just transitioned to Matlib 2.0. </h1><br/> <p>${email}</p> <br/><p>Viewing on: ${
      isMobile ? "Mobile" : "Large"
    } screen</p>`;

    try {
      axios.post(
        `https://matlib.co/wp-json/ml/v1/email?subject=${subject}&body=${body}&sender=${email}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (err) {
      if (err.response.status === 500) {
        console.log("Oops! There was a problem with the server");
      }
    }
  };

  const handleEmail = () => {
    const userName = localStorage.getItem("userName");
    const userRole = localStorage.getItem("userRole");
    const userBrand = localStorage.getItem("userBrand");

    const subject = `Matlib - ${userName} login from ${userBrand}`;
    const body = `<h1>${userRole} - ${userName} from ${userBrand}</h1> <p>Has just logged into Matlib.</p> <p>Viewing on: ${
      isMobile ? "Mobile" : "Large"
    } screen</p>`;

    try {
      axios.post(
        `https://matlib.co/wp-json/ml/v1/email?subject=${subject}&body=${body}&sender=${email}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );
    } catch (err) {
      if (err.response.status === 500) {
        console.log("Oops! There was a problem with the server");
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userBrand");
    localStorage.removeItem("userRole");
    changeLoggedIn(false);
    window.location.href = `./`;
  };

  const handleGoToLibrary = () => {
    history.push(`./lib`);
  };

  return (
    <div className="login-template">
      {user[0].userName ? (
        <Container className="login-container">
          <div className={`login-message ${isDark && "dark"}`}>
            <h4>Hey {user[0].userName}, </h4>
            <h5>You're {loggedIn ? "Logged In" : "Not logged In"}</h5>
            {/* <h4>as {user[0].userRole} for {user[0].userBrand}</h4> */}
          </div>

          {loading && <LinearProgress color="secondary" />}

          <Button
            color="secondary"
            fullWidth
            type="submit"
            variant="contained"
            style={{ backgroundColor: "#C4123C", margin: "5px 0" }}
            onClick={handleLogout}
          >
            Logout
          </Button>
          <Button
            color="secondary"
            fullWidth
            variant="contained"
            style={{
              backgroundColor: "#Fefefe",
              color: "#222",
              margin: "5px 0",
            }}
            onClick={handleGoToLibrary}
          >
            Go to Library
          </Button>
        </Container>
      ) : (
        <Container className="login-container">
          {jumpName ? (
            <span
              style={{
                display: "flex",
                padding: "0 0 26px 0",
                flexDirection: "column",
                textTransform: "uppercase",
                color: isDark ? "#313131" : "inherit",
              }}
            >
              <h3>{`Hi, ${jumpName}`}</h3>
              <h4>Sign in with Email</h4>
            </span>
          ) : (
            <span
              style={{
                display: "flex",
                padding: "0 0 26px 0",
                flexDirection: "column",
                textTransform: "uppercase",
                color: isDark ? "#313131" : "inherit",
              }}
            >
              <h3>Welcome to Matlib</h3>
              <h4>Sign in with Email</h4>
            </span>
          )}
          <form onSubmit={onFormSubmit}>
            <TextField
              fullWidth
              value={email}
              label="Email Address"
              name="email"
              size="small"
              type="text"
              variant="outlined"
              autoComplete="false"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ margin: "5px 0", outline: "none" }}
              onChange={(event) => setEmail(event.target.value)}
            />

            <TextField
              fullWidth
              value={password}
              label="Password"
              name="password"
              size="small"
              type="password"
              variant="outlined"
              autoComplete="false"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ margin: "5px 0", outline: "none" }}
              onChange={(event) => setPassword(event.target.value)}
            />

            <Button
              color="secondary"
              fullWidth
              type="submit"
              variant="contained"
              style={{ backgroundColor: "#C4123C", margin: "5px 0" }}
            >
              Log in
            </Button>
            {loading && <LinearProgress color="secondary" />}
            {/* <div className="login-error">Something is wrong with your login. Check and try again</div> */}
            {error ? (
              <div className="login-error">
                Something is wrong with your login. Check and try again
              </div>
            ) : (
              ""
            )}
          </form>
        </Container>
      )}
    </div>
  );
}

export default Login;
