import { useState, createContext, useEffect } from "react";

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const localUserId = localStorage.getItem("userId");
  const localUserName = localStorage.getItem("userName");
  const localUserEmail = localStorage.getItem("userEmail");
  const localUserBrand = localStorage.getItem("userBrand");
  const localUserRole = localStorage.getItem("userRole");
  const localDark = localStorage.getItem("isDark");
  const token = localStorage.getItem("token");

  const [user, setUser] = useState([
    {
      userId: localUserId,
      userName: localUserName,
      userEmail: localUserEmail,
      userBrand: localUserBrand,
      userRole: localUserRole,
    },
  ]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isDark, setIsDark] = useState(false);

  const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  useEffect(() => {
    if (!localDark) {
      localStorage.setItem("isDark", prefersDark);
      setIsDark(prefersDark);
    } else {
      setIsDark(JSON.parse(localDark));
    }
  }, [localDark, prefersDark]);

  const body = document.querySelector("body");

  useEffect(() => {
    if (window.screen.width <= 760) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (token) {
      setLoggedIn(true);
      //localStorage.setItem('_mofo_1', btoa(JSON.stringify(user)))
    }
  }, [token, user]);

  const addUser = (userId, userName, userEmail, userBrand, userRole) => {
    setUser([{ userId, userName, userEmail, userBrand, userRole }]);
  };
  const changeLoggedIn = (value) => {
    setLoggedIn(value);
  };
  const changeIsDark = (value) => {
    setIsDark(value);
    localStorage.setItem("isDark", value);
  };

  useEffect(() => {
    if (isDark === true) {
      body.classList.add("dark");
    } else {
      body.classList.remove("dark");
      //body.classList.add("dark")
    }
  }, [isDark, body.classList]);

  return (
    <UserContext.Provider
      value={{
        user,
        addUser,
        loggedIn,
        changeLoggedIn,
        isMobile,
        token,
        isDark,
        changeIsDark,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
