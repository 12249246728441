import { useState, useEffect } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import "../styles/components.scss";
import Swatch from "../components/Swatch";

function Test() {
  const [materials, setMaterials] = useState([]);
  const [loaded, setLoaded] = useState(false);
  //const isDark = true;

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append("Access-Control-Allow-Origin", "http://localhost:3000");
  headers.append("Access-Control-Allow-Credentials", "true");

  //const URL = `http://localhost:5001/material/`;
  // const URL = `https://matlib.co/wp-json/ml/v1/materials/filter`;

  // axios.get(URL).then((res) => {
  //   setLoaded(true);
  //   setMaterials(res.data);
  // });

  const URL = `https://matlib.co/wp-json/ml/v1/materials/unpublished`;
  //const URL = `http://localhost:5001/material/`;

  const { data, isError, isSuccess, isLoading } = useQuery("materials", () =>
    axios(URL)
  );

  useEffect(() => {
    if (isError) {
      //setMessage("error");
    }
    if (isLoading) {
      //setMessage("loading");
    }
    if (isSuccess) {
      setMaterials(data.data);
      //setMessage("");
      setLoaded(true);
    }
  }, [isLoading, isSuccess, isError, data]);

  // useEffect(() => {
  //   if (isError) {
  //     console.log("error");
  //   }
  //   if (isLoading) {
  //     console.log("loading");
  //     setLoaded(false);
  //   }
  //   setLoaded(false);
  //   if (isSuccess) {
  //     setMaterials(data.data);
  //     setLoaded(true);
  //   }
  // }, [isLoading, isSuccess, isError, data]);

  // useEffect(() => {
  //   const items = materials.filter((item) => {
  //     return item.price >= 2.02 && item.price <= 2.1;
  //   });
  //   console.table(items);
  // }, [materials]);

  return (
    <div className={`array-container`}>
      <div className="content">
        {loaded &&
          materials.map((material) => (
            <Swatch key={material.id} material={material} />
          ))}
      </div>
    </div>
  );
}

export default Test;
