import { useContext } from "react";

//import fuzzysort from "fuzzysort";
import Fuse from "fuse.js";
import { MatlibContext } from "../contexts/MatlibContext";

function FuzzySortComponent({ slug }) {
  const { materials } = useContext(MatlibContext);

  const getSlug = slug.replaceAll("-", " ");

  //   const optionsFuzzy = {
  //     limit: 1000, // don't return more results than you need!
  //     allowTypo: true, // if you don't care about allowing typos
  //     threshold: Infinity, // don't return bad results
  //     keys: ["title", "slug"],
  //   };

  //const resultsFuzzy = fuzzysort.go(slug, materials, optionsFuzzy);
  const options = {
    includeScore: true,
    threshold: 0.4,
    distance: 1000,
    keys: ["title", "slug"],
  };

  const fuse = new Fuse(materials, options);

  const resultsFuzzy = fuse.search(slug).slice(0, 10);

  console.log(resultsFuzzy);

  return (
    <div>
      <h3>Couldn't find {getSlug}</h3>
      {resultsFuzzy.length !== 0 && <h5>Could it be one of these?</h5>}
      <ul>
        {resultsFuzzy?.map((item) => (
          <a
            key={item.item.id}
            href={`../${item.item.vendor.toLowerCase()}/${item.item.slug}`}
          >
            <li>{item.item.title}</li>
          </a>
        ))}
        <li style={{ textAlign: "right" }}>
          <a href={`../lib`}>Back to Library</a>
        </li>
      </ul>
    </div>
  );
}

export default FuzzySortComponent;
