import { useContext, useState, useEffect } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import "../styles/pages.scss";
import { UserContext } from "../contexts/UserContext";
import vintage1 from "../images/vintage1.jpg";
import vintage2 from "../images/vintage2.jpg";
import vintage3 from "../images/vintage3.jpg";
import vintage4 from "../images/vintage4.jpg";
import vintage5 from "../images/vintage5.jpg";
import { makeStyles } from "@material-ui/core/styles";
import matlibLogo from "../images/matlibLogo.svg";
import LinearProgress from "@material-ui/core/LinearProgress";
//import { random } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "white",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  cssLabel: {
    color: "#414141",
    border: "thin solid #414141",
    borderColor: `#414141 !important`,
  },
  cssLabelDark: {
    color: "#FEFEFE",
    border: "thin solid #FEFEFE",
    borderColor: `#FEFEFE !important`,
  },
  inputBase: {
    color: "#414141",
    border: "thin solid #414141",
    borderColor: `#414141 !important`,
    padding: theme.spacing(2),
    borderRadius: "2px",
  },
  inputBaseDark: {
    color: "#FEFEFE",
    border: "thin solid #FEFEFE",
    borderColor: `#FEFEFE !important`,
    padding: theme.spacing(2),
    borderRadius: "2px",
  },
}));

function Contact() {
  const { user, token, isDark, isMobile } = useContext(UserContext);
  const { userName, userEmail } = user[0];

  const [tempEmail, setTempEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [backgroundPic, setBackgroundPic] = useState("");
  const [picLoaded, setPicLoaded] = useState(false);

  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (subject !== "" && message !== "") {
      try {
        axios.post(
          `https://matlib.co/wp-json/ml/v1/email?subject=${subject}&body=${message}&sender=${
            userEmail ? userEmail : tempEmail
          }`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + token,
            },
          }
        );

        setSubject("");
        setMessage("");
        setSubmitted(true);
        setUserMessage("Thanks for your message");
      } catch (err) {
        if (err.response.status === 500) {
          console.log("Oops! There was a problem with the server");
        }
      }
    } else {
      setSubmitted(false);
      setUserMessage("Seems like theres something missing");
    }
  };

  useEffect(() => {
    const pictureArray = [vintage1, vintage2, vintage3, vintage4, vintage5];
    const randomIndex = Math.floor(Math.random() * pictureArray.length);
    setBackgroundPic(pictureArray[randomIndex]);
  }, []);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setPicLoaded(true);
    };
    img.src = backgroundPic;
  }, [backgroundPic]);

  if (picLoaded) {
    return (
      <div className="contact-page">
        <div
          className="contact-page-information"
          style={{
            backgroundImage: `linear-gradient(${
              isMobile ? "180deg" : "90deg"
            },rgba(0,0,0,0.0) 99%, rgba(0,0,0,0.7) 100%), url(${backgroundPic})`,
          }}
        >
          <img src={matlibLogo} alt="matlib" />
          <h4>Digital Material Library</h4>
        </div>

        <div className="input-form">
          <div className="contact-text-header">
            <h2>Hey, {userEmail ? userName : "there"}. </h2>
            <h3>
              {userMessage
                ? userMessage
                : "Let us know what you're thinking..."}
            </h3>
          </div>

          {!submitted ? (
            <form onSubmit={handleSubmit}>
              {!userEmail && (
                <InputBase
                  className={isDark ? classes.inputBaseDark : classes.inputBase}
                  placeholder="Your Email"
                  multiline
                  value={tempEmail}
                  variant="outlined"
                  onChange={(e) => setTempEmail(e.target.value)}
                  style={{ width: "100%", margin: "10px" }}
                />
              )}
              <InputBase
                className={isDark ? classes.inputBaseDark : classes.inputBase}
                placeholder="Your Subject"
                multiline
                value={subject}
                variant="outlined"
                onChange={(e) => setSubject(e.target.value)}
                style={{ width: "100%", margin: "10px" }}
              />
              <InputBase
                placeholder="Message"
                multiline
                className={isDark ? classes.inputBaseDark : classes.inputBase}
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                variant="outlined"
                style={{ width: "100%", margin: "10px" }}
              />
              <span className="contact-user-message">{userMessage}</span>
              <Button
                variant="contained"
                color="secondary"
                style={{ backgroundColor: "#CA3E47", margin: "10px" }}
                type="submit"
              >
                Submit
              </Button>
            </form>
          ) : null}
        </div>
      </div>
    );
  }
  return (
    <div style={{ width: "100%", position: "absolute", height: "2px" }}>
      <LinearProgress color="secondary" />
    </div>
  );
}

export default Contact;
