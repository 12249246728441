export const nikeLogo = (
  <svg
    version="1.0"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <g>
      <path
        d="M22.7,8.1c-0.5,0.2-1,0.4-1.4,0.6c-1,0.4-1.9,0.8-2.9,1.2c-1.2,0.5-2.4,1-3.6,1.6c-1.4,0.6-2.9,1.2-4.3,1.8
		C9,14,7.5,14.7,6,15.3c-0.7,0.3-1.4,0.5-2.1,0.6c-0.8,0.1-1.5,0.1-2.2-0.4c-0.7-0.4-1-1-1.1-1.8c0-1,0.3-1.9,0.8-2.7
		C2,9.9,2.8,9,3.6,8.1c0,0,0,0,0.1-0.1C3.6,8.3,3.5,8.5,3.4,8.7C3.1,9.4,2.9,10.1,3,10.9c0.2,0.9,0.8,1.4,1.7,1.6
		c0.7,0.1,1.4,0,2.1-0.2c2.2-0.6,4.4-1.2,6.5-1.7c1.2-0.3,2.4-0.6,3.6-1C17.9,9.3,19,9,20.1,8.7C20.9,8.5,21.8,8.3,22.7,8.1
		C22.7,8,22.7,8,22.7,8.1C22.7,8.1,22.7,8.1,22.7,8.1z"
      />
    </g>
  </svg>
);

export const downCircleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
    />
  </svg>
);

export const closeTagIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
      clipRule="evenodd"
    />
  </svg>
);

export const checkCircleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

export const downloadIcon = (
  <div className="download-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  </div>
);

export const pdfIcon = (
  <div className="pdf-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
      />
    </svg>
  </div>
);

export const closeIcon = (
  <div className="close-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </div>
);
