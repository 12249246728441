import { useContext, useEffect, useState } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import "../styles/pages.scss";
import Swatch from "../components/Swatch";
import { MatlibContext } from "../contexts/MatlibContext";
import { UserContext } from "../contexts/UserContext";
import { useHistory } from "react-router-dom";
import CollectionImage from "../components/utils/CollectionImage";
import axios from "axios";
//import EmailNotification from '../components/EmailNotification';

const temporaryDownloadButtonStyle = {
  width: "334px",
  height: "280px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  padding: "10px",
  background: "#C4123C50",
  borderRadius: "4px",
  justifyContent: "center",
  fontFamily: "GT-Walsheim-Bold",
  textAlign: "center",
};
const temporaryInputStyle = {
  height: "40px",
  fontSize: "28px",
  textAlign: "Center",
  fontFamily: "GT-Walsheim-Regular",
};
const temporaryTextareaStyle = {
  fontSize: "22px",
  textAlign: "Center",
  fontFamily: "GT-Walsheim-Regular",
};

function Collection() {
  const { like, materials } = useContext(MatlibContext);
  const { isDark } = useContext(UserContext);
  const [collection, setCollection] = useState([]);
  const [loaded, setLoaded] = useState(false);
  /// Collection PDF section
  const [pdfTitle, setPdfTitle] = useState(`Collection 2021`);
  const [pdfDescription, setPdfDescription] = useState("");
  const [collectionPDFLink, setCollectionPDFLink] = useState("");

  const hidden = false; /// Hide the collection input

  const history = useHistory();

  useEffect(() => {
    if (materials.length >= 1) {
      var update = [];
      like.forEach((item) => {
        var n = materials.find((element) => element.id === item);
        update.push(n);
      });
      setCollection(update);
      setLoaded(true);
    }
  }, [like, materials]);

  function handleLibrary() {
    history.push("/lib");
  }
  const handleDownload = () => {
    const getCollection = collection.map((item) => ({
      name: item.title,
      vendor: item.vendor,
      slug: item.slug,
      image: `http://localhost:5001/${item.vendor.toLowerCase()}/${
        item.slug
      }/full/${item.gallery_large[0].substring(
        item.gallery_large[0].lastIndexOf("/") + 1
      )}`,
    }));

    axios
      .post("http://localhost:5001/pdf", {
        material: getCollection,
        pageTitle: pdfTitle,
        collectionDescription: pdfDescription,
      })
      .then((res) => setCollectionPDFLink(res.data));
  };

  const backArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 19l-7-7 7-7"
      />
    </svg>
  );

  // const materialList = useMemo(
  //   () =>
  //     collection.map((material) => (
  //       <Swatch key={material.id} material={material} />
  //     )),
  //   [collection]
  // );

  const [, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <div className="collection">
      {like.length !== 0 && collection.length === 0 && (
        <LinearProgress className="library-progress" color="secondary" />
      )}
      {like.length !== 0 && collection.length === 0 && (
        <div className="fallback-text">Loading up the materials</div>
      )}
      <div className="array-container">
        {collection.length === 0 && like.length === 0 ? (
          <div className="fallback-text styled-background">
            <h2>You haven't added any materials to your colletion yet</h2>
            <CollectionImage isDark={isDark} />
            <hr />
            <button onClick={handleLibrary}>
              {backArrow} <h3>BACK TO LIBRARY</h3>
            </button>
          </div>
        ) : (
          <>
            <div className="content">
              {loaded &&
                collection.map((material) => (
                  <Swatch key={material.id} material={material} />
                ))}
              {hidden && (
                <div
                  className="pdf-download-section"
                  style={temporaryDownloadButtonStyle}
                >
                  <h3>Collection PDF</h3>
                  <input
                    type="text"
                    placeholder="collection title"
                    style={temporaryInputStyle}
                    onChange={(e) => setPdfTitle(e.target.value)}
                  />
                  <textarea
                    style={temporaryTextareaStyle}
                    placeholder="collection description"
                    rows="4"
                    cols="50"
                    onChange={(e) => setPdfDescription(e.target.value)}
                  />
                  <button onClick={handleDownload}>
                    CLICK to open collection in Downloadable PDF Format
                  </button>
                  {collectionPDFLink && (
                    <a
                      href={collectionPDFLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h5>click here for the PDF</h5>
                    </a>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Collection;
