import axios from "axios";
import { useQuery } from "react-query";
import Swatch from "../components/Swatch";

function Query() {
  //const [materials, setMaterials ] = useState([])

  const search = "";

  const { data, isSuccess, isLoading } = useQuery("materials", () =>
    axios(`https://matlib.co/wp-json/ml/v1/materials/filter?search=${search}`)
  );

  return (
    <div className="query-container">
      {isSuccess &&
        data.data.map((material) => (
          <Swatch material={material} key={material.id} />
        ))}
      {isLoading && <div className="fallback-text">Loading materials...</div>}
    </div>
  );
}

export default Query;
