import { useContext } from "react";
import { MatlibContext } from "../contexts/MatlibContext";
import Swatch from "../components/Swatch";

import Fuse from "fuse.js";

function SimilarMaterials({ searchTerm, current, type }) {
  const { materials } = useContext(MatlibContext);

  const preFilterMaterials = materials
    .filter((item) => item.type === type)
    .map((items) => items);

  const options = {
    // includeScore: true,
    shouldSort: true,
    tokenize: true,
    matchAllTokens: true,
    findAllMatches: true,
    threshold: 0.8,
    location: 10,
    distance: 100,
    maxPatternLength: 3,
    minMatchCharLength: 1,
    keys: ["slug", "search"],
  };

  const fuse = new Fuse(preFilterMaterials, options);

  const splitSearch = searchTerm
    .replace(/,/g, ", ")
    .replace(/^/, ", ")
    .replace(/^/, current.replace(/-/g, ", "));

  const results = fuse.search({
    $and: [{ slug: current.replace(/-/g, " ") }, { search: splitSearch }],
  });

  return (
    <div className="single-array-container">
      {!results && <div className="fallback-text">Updating Collection</div>}
      {results.length === 0 && (
        <div className="fallback-text">Searching for similar articles</div>
      )}
      {results &&
        results
          .filter((curr) => curr.item.slug !== current)
          .map((material) => (
            <Swatch key={material.item.id} material={material.item} />
          ))}
    </div>
  );
}

export default SimilarMaterials;
