import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import Swatch from "../components/Swatch";
import { UserContext } from "../contexts/UserContext";
import { MatlibContext } from "../contexts/MatlibContext";
const Vendor = () => {
  const { slug } = useParams();

  const [vendors, setVendors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  //const [materials, setMaterials] = useState([]);
  const vendor = slug === undefined ? "" : slug;

  const { token } = useContext(UserContext);
  const { materials } = useContext(MatlibContext);

  const filteredMaterials = materials
    .filter((mat) => mat.vendor.toLowerCase() === slug)
    .map((item) => item);

  //const materials = mats.filter((vend) => vend.vendor === slug);

  // useEffect(() => {
  //   axios
  //     .get(`https://matlib.co/wp-json/ml/v1/materials/filter?search=${search}`)
  //     .then((res) => {
  //       setMaterials(res.data);
  //       setLoaded(true);
  //     });
  //   // eslint-disable-next-line
  // }, [slug]);

  useEffect(() => {
    axios
      .get(`https://matlib.co/wp-json/ml/v1/vendors?vendor=${vendor}`)
      .then((res) => {
        setVendors(res.data);
        setLoaded(true);
      });
    // eslint-disable-next-line
  }, [slug]);

  useEffect(() => {
    const emailRecipients = "info@matlib.co";
    const emailSubject = `Entry though Vendor Page: ${slug}`;
    const body = "Anonomous user entered through the Vendor page";
    if (!token) {
      try {
        axios.post(
          `https://matlib.co/wp-json/ml/v1/email?userEmail=${emailRecipients}&subject=${emailSubject}&body=${body}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } catch (err) {
        if (err.response.status === 500) {
          console.log("Oops! There was a problem with the server");
        }
      }
    }
  }, [slug, token]);

  if (slug && loaded) {
    return (
      <div className="vendor-single-array">
        <div
          className="vendor-header"
          style={{ backgroundImage: `url(${vendors[0].vendor_image[0]})` }}
        >
          <div className="vendor-header-left">
            <img src={vendors[0].vendor_logo[0]} alt={vendors[0].slug} />
            <h4>{vendors[0].vendor_tag}</h4>
          </div>
          <div className="vendor-header-right">
            {filteredMaterials.length !== 0 ? (
              <h1>{filteredMaterials.length} Materials</h1>
            ) : (
              <h1>Loading Materials</h1>
            )}
          </div>
        </div>
        <div className="vendor-material-array">
          {filteredMaterials.map((material) => (
            <Swatch key={material.id} material={material} />
          ))}
        </div>
      </div>
    );
  }

  if (!slug && loaded) {
    return (
      <div className="vendor-array">
        {vendors &&
          vendors.map((vendor) => {
            return (
              <div key={vendor.id} className="vendor-card">
                <div
                  className="vendor-image"
                  style={{ backgroundImage: `url(${vendor.vendor_image[0]})` }}
                >
                  <img src={vendor.vendor_logo[0]} alt={vendor.vendor_name} />
                  <div className="vendor-button">
                    <Link to={`vendor/${vendor.slug}`}>
                      View {vendor.vendor_name} materials
                    </Link>
                  </div>
                </div>
                <div className="vendor-content">
                  <div className="vendor-tag">
                    <h4>{vendor.vendor_tag}</h4>
                  </div>
                  <div className="vendor-description">
                    <h5>{vendor.vendor_description}</h5>
                  </div>
                  <div className="vendor-blocks">
                    <div className="locations-block">
                      <h4>
                        Production Location
                        {vendor.vendor_location.length === 1 ? "" : "s"}:
                        <ul>
                          {vendor.vendor_location.map((location, i) => {
                            return <li key={i}> {location.country}</li>;
                          })}
                        </ul>
                      </h4>
                    </div>
                    <div className="divider"></div>
                    <div className="contact-block">
                      <h4>Contact for {vendor.vendor_name}</h4>
                      <h5>{vendor.vendor_contacts[0].vendor_contact_name}</h5>
                      <h5>{vendor.vendor_contacts[0].vendor_contact_email}</h5>
                    </div>
                  </div>
                  <div className="vendor-website">
                    <a
                      href={vendor.vendor_website}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {vendor.vendor_website}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  }

  return (
    <>
      {!loaded && (
        <div className="fallback-text">Loading {slug ? slug : "vendors"}</div>
      )}
    </>
  );
};

export default Vendor;
