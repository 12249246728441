import Footer from "./gui/Footer";
import { useLocation } from 'react-router-dom'

import '../styles/layout.scss'

function Layout({children}) {

    const location = useLocation();


    const LayoutView = () => {
        /// use
        if(location.pathname === '/'){
        return (
            <main className="landing-container">{children}</main>
        )}
        return (
            <>
            <main className="container">{children}</main>
            <div className="footer">
                <Footer />
            </div>

           </>
        )
    }

    return (
       <LayoutView />
    )
}

export default Layout
