import { useState, useEffect, createContext } from "react";
import axios from "axios";
import { useQuery } from "react-query";

//import { UserContext } from "../contexts/UserContext";
import ReactGA from "react-ga";

export const MatlibContext = createContext();

const MatlibContextProvider = (props) => {
  //const { loggedIn, user } = useContext(UserContext);

  const localLike = JSON.parse(localStorage.getItem("matId"));

  const [materials, setMaterials] = useState([]);
  const [message, setMessage] = useState("");
  const [like, setLike] = useState(localLike ? localLike : []);
  const [likeMaterial, setLikeMaterial] = useState([]);
  const [search, setSearch] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(true);
  const [fuzzySort, setFuzzySort] = useState("");
  const [orderBy, setOrderBy] = useState("modified");
  const [fuzzyResults, setFuzzyResults] = useState([]);
  const [storeNike, setStoreNike] = useState([]);
  //const userId = 30; //user[0].userId;

  const URL = `https://matlib.co/wp-json/ml/v1/materials/filter`;
  //const URL = `http://localhost:5001/material/`;

  const { data, isError, isSuccess, isLoading } = useQuery("materials", () =>
    axios(URL)
  );

  useEffect(() => {
    if (isError) {
      setMessage("error");
    }
    if (isLoading) {
      setMessage("loading");
    }
    setLoaded(false);
    if (isSuccess) {
      setMaterials(data.data);
      setMessage("");
      setLoaded(false);
    }
  }, [isLoading, isSuccess, isError, data]);

  // useEffect(() => {
  //     const fetchData = async () => {
  //     try {
  //         const response = await axios.get(`http://localhost:8888/Matlib-wp-react/wp-json/ml/v1/test/like?loggedIn=${loggedIn}&userId=${userId}`);
  //         // Success
  //         if(response.data !== null){
  //             let remote = [];
  //             let data = JSON.parse(response.data)
  //             if(data){
  //                 for (var i = 0; i < data.length; i++) {
  //                     remote.push(data[i].matId)
  //                 }
  //             }
  //             const parsed = remote.map(JSON.parse);
  //             setLike(parsed)
  //         }else{
  //             if(localLike){
  //                 setLike(localLike)
  //             }

  //         }
  //     } catch (error) {
  //         // Error
  //         if (error.response) {
  //             /*
  //              * The request was made and the server responded with a
  //              * status code that falls out of the range of 2xx
  //              */
  //             console.log('localLike', localLike)
  //             console.log(error.response.data);
  //             console.log(error.response.status);
  //             console.log(error.response.headers);
  //         } else if (error.request) {
  //             /*
  //              * The request was made but no response was received, `error.request`
  //              * is an instance of XMLHttpRequest in the browser and an instance
  //              * of http.ClientRequest in Node.js
  //              */
  //             console.log(error.request);
  //         } else {
  //             // Something happened in setting up the request and triggered an Error
  //             console.log('Error', error.message);
  //         }
  //         console.log(error);
  //     }
  // }
  // fetchData();
  // }, []);

  useEffect(() => {
    localStorage.setItem("matId", JSON.stringify(like));
    //const sendLike = like.toString()
    //axios.post(`http://localhost:8888/Matlib-wp-react/wp-json/ml/v1/test/like?loggedIn=${loggedIn}&userId=${userId}&materialIds=${sendLike}`)
  }, [like]);

  const fetchURL = "https://designer.matlib.co/data/nikeStore.json";
  //const fetchURL = "http://localhost:3000/data/nikeStore.json";

  const getData = () => {
    fetch(fetchURL, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        //console.log(response);
        return response.json();
      })
      .then((data) => {
        setStoreNike(data);
      })
      .catch((err) => {
        // Do something for an error here
        console.log("Error Reading data " + err);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const value = {
    loaded,
    storeNike,
    message,
    materials,
    likeMaterial,
    changeMaterials: (value) => {
      setMaterials(value);
    },
    like,
    changeLike: (value) => {
      setLike([...like, value]);
      var n = materials.find((element) => element.id === value);
      ReactGA.event({
        category: "like-material",
        action: `${n.vendor} - ${n.title}`,
      });
      setLikeMaterial((oldArray) => [...oldArray, n]);
    },
    removeLike: (value) => {
      setLike(like.filter((like) => like !== value));
      var n = likeMaterial.filter((element) => element.id !== value);
      setLikeMaterial(n);
    },
    removeAllLike: (value) => {
      setLike([]);
      setLikeMaterial([]);
    },
    search,
    changeSearch: (value) => {
      setSearch(value);
    },
    triggerFilter,
    changeTriggerFilter: (value) => {
      setTriggerFilter(value);
    },
    fuzzySort,
    changeFuzzySort: (value) => {
      setFuzzySort(value);
    },
    fuzzyResults,
    changeFuzzyResults: (value) => {
      setFuzzyResults(value);
    },
    orderBy,
    changeOrderBy: (value) => {
      setOrderBy(value);
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setTriggerFilter(false);
    }, 10000);
  }, []);

  return (
    <MatlibContext.Provider value={value}>
      {props.children}
    </MatlibContext.Provider>
  );
};

export default MatlibContextProvider;
