import { useState, useEffect, useContext } from "react";
import Like from "../components/gui/Like";
import { UserContext } from "../contexts/UserContext";
import { MatlibContext } from "../contexts/MatlibContext";
import { useHistory } from "react-router";
import KindLeatherLogo from "./utils/KindLeatherLogo";
//import storeNike from "../storeNike.json";
//import axios from "axios";
import ReactGA from "react-ga";

import { closeIcon } from "../components/utils/Icons";

function MaterialModal({
  id,
  materialTitle,
  materialVendor,
  showModal,
  setShowModal,
  imageIndex,
}) {
  const history = useHistory();

  const [material, setMaterial] = useState([]);
  const [viewing, setViewing] = useState();
  const [loaded, setLoaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageCount, setImageCount] = useState();
  const [download, setDownload] = useState();
  const [brandData, setBrandData] = useState([]);

  const { isMobile, user } = useContext(UserContext);
  const { materials, changeFuzzySort, storeNike } = useContext(MatlibContext);

  const body = document.querySelector("body");

  //   function add_view_count(id) {
  //     axios
  //       .post(`https://matlib.co/wp-json/ml/v1/count/?id=${id}`)
  //       .then((response) => console.log(response));
  //     // fetch(`https://matlib.co/wp-json/ml/v1/count/?id=3383`),{
  //     //     method: "POST",
  //     //     headers: {
  //     //         'Content-Type': 'application/json',
  //     //         'accept': 'application/json'
  //     //     }

  //     // }
  //   }

  async function fetchData(id) {
    const data = materials.find((mat) => mat.id === id);

    ReactGA.event({
      category: "view-swatch",
      action: `${data.slug}`,
    });

    // Open up to any user to see Nike info.
    // Should change this..
    const myMats = storeNike;
    setBrandData(myMats.filter((mat) => mat.slug === data.slug));

    //
    if (user[0].userBrand === "Nike") {
      const myMats = storeNike;
      setBrandData(myMats.filter((mat) => mat.slug === data.slug));
    }

    if (user[0].userRole === "vendor") {
      const myMats = storeNike;
      setBrandData(myMats.filter((mat) => mat.slug === data.slug));
    }
    if (user[0].userRole === "designer") {
      //add_view_count(id);
    }

    setMaterial(data);
    setViewing(data.gallery_medium[imageIndex]);
    setDownload(data.gallery_large[imageIndex]);
    setLoaded(true);
    body.style.overflow = "hidden";
    setImageCount(data.gallery_medium.length);
  }

  const handleChangeView = (img, i) => {
    setImageLoaded(false);
    setViewing(img);
    setDownload(material.gallery_large[i]);
  };

  const handleSingle = () => {
    body.style.overflow = "auto";
    history.push(`/${material.vendor.toLowerCase()}/${material.slug}`);
  };

  useEffect(() => {
    if (showModal) {
      fetchData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleLoaded = () => {
    setImageLoaded(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    //setSingleLoader(false)
    body.style.overflow = "auto";
  };

  const handleLinkedItem = (item) => {
    changeFuzzySort(item);
    setShowModal(false);
    history.push("/lib");
    body.style.overflow = "auto";
  };

  var downloadedImg;

  function startDownload() {
    let imageURL = download;
    downloadedImg = new Image();
    downloadedImg.crossOrigin = "https://designer.matlib.co";
    downloadedImg.addEventListener("load", imageReceived, false);
    downloadedImg.src = imageURL;
  }

  function imageReceived() {
    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d");
    const title = material.vendor + " - " + material.title;
    canvas.width = downloadedImg.width;
    canvas.height = downloadedImg.height;

    context.clearRect(0, 0, downloadedImg.height, downloadedImg.width);
    context.drawImage(downloadedImg, 0, 0);
    context.font = "bold 16px Helvetica, sans-serif";
    context.fillStyle = "white";
    context.texAlign = "left";
    context.shadowColor = "black";
    context.shadowBlur = 2;
    context.fillText(title.toUpperCase(), 10, 20);

    try {
      // localStorage.setItem(
      //   "saved-image-example",
      //   canvas.toDataURL("image/jpg")
      // );
      var url = canvas
        .toDataURL("image/jpg")
        .replace("image/jpg", "image/octet-stream");
      var link = document.createElement("a");
      link.download = `${material.slug}-${material.vendor}.jpg`;
      link.href = url;
      link.click();
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  //   const handleFullView = () => {
  //     var link = document.createElement('a');
  //     link.href = `/${material.vendor}/${material.slug}`;
  //     link.click();
  //   }
  function dataState(term) {
    switch (term) {
      case "Discover":
        return "Discovery";
      case "Release":
        return "Released";
      default:
        return "Unknown";
    }
  }
  if (isMobile) {
    return (
      <>
        {showModal && (
          <div className="modal-shade">
            <div className="material-modal">
              <div className="modal-content">
                <span className="modal-close" onClick={handleCloseModal}>
                  {closeIcon}
                </span>
                <div className="modal-image">
                  {!imageLoaded && (
                    <div className="image-loader">
                      <div className="loader-spinner"></div>
                    </div>
                  )}

                  <img
                    src={viewing}
                    onLoad={handleLoaded}
                    data-loading={imageLoaded}
                    alt={material.title}
                  />

                  {loaded && (
                    <div className="image-counter">
                      {material.gallery_medium.map((imgCnt, i) =>
                        imageCount <= 1 ? (
                          ""
                        ) : (
                          <button
                            key={i}
                            onClick={() => handleChangeView(imgCnt, i)}
                            style={
                              viewing === imgCnt
                                ? { background: "transparent" }
                                : { background: "rgba(255,255,255,0.7)" }
                            }
                            disabled={viewing === imgCnt ? true : false}
                          ></button>
                        )
                      )}
                    </div>
                  )}
                </div>
                <div className="modal-sidebar">
                  <div className="modal-text-body">
                    {loaded ? (
                      <>
                        {/* {material.days_added <= 30 && <div style={{ opacity:'0.6', fontSize:'10px', textTransform:'uppercase', color:'pink'}}>Recently Added</div> }
                            {material.days_modified <= 30 && material.days_added >= 30 ? <div style={{ opacity:'0.6', fontSize:'10px', textTransform:'uppercase', color:'#a8cff1'}}>Recently Modified</div> : null} */}
                        <h4 style={{ margin: "3px" }}>{material.vendor}</h4>
                        <h2>{material.title}</h2>

                        <h4 style={{ opacity: "0.6", marginBottom: "0px" }}>
                          {material.description}
                        </h4>

                        <div className="table">
                          <div className="column" style={{ width: "40%" }}>
                            <div className="column-header">Pricing</div>
                            <div className="table-content">
                              <h4
                                style={{ fontSize: "13px", fontWeight: "bold" }}
                              >
                                ${material.price} / sq.ft.
                              </h4>
                              <div
                                className="price-disclamer"
                                style={{
                                  fontSize: "9px",
                                  color: "#FEFEFE70",
                                  textAlign: "center",
                                }}
                              >
                                Reference Price
                              </div>
                            </div>
                          </div>
                          <div className="column" style={{ width: "60%" }}>
                            <div className="column-header">
                              Material Information
                            </div>
                            <div className="table-content">
                              <h5>
                                <strong>Substrate:</strong> {material.substrate}
                              </h5>
                              <h5>
                                <strong>Type:</strong> {material.type}
                              </h5>
                              <h5>
                                <strong>Sub-Type:</strong> {material.subtype}
                              </h5>
                            </div>
                          </div>
                        </div>

                        {/* <div className="table">
                                     <div className="column" style={{width:'50%'}}>
                                        <div className="column-header" >Material Character</div>
                                        <div className="table-content">
                                           {material.character[0] && material.character[0].map((character, i) =><h5 key={i} className="linked-item" onClick={()=>handleLinkedItem(character)} >{character}</h5>)}
                                    </div>
                                        
                                    </div>
                                     <div className="column" style={{width:'50%'}}>
                                     <div className="column-header" >Material Finish</div>
                                        <div className="table-content">
                                        {material.feature[0] && material.feature[0].map((finish, i) => <h5 key={i} className="linked-item" onClick={()=>handleLinkedItem(finish)}>{finish}</h5>)}                                                       
                                        </div>
                                    </div>
                                 </div> */}
                      </>
                    ) : (
                      <>
                        <h4 style={{ margin: "0", opacity: "0.6" }}>
                          {materialVendor}
                        </h4>
                        <h2>{materialTitle}</h2>
                        <h4 style={{ margin: "0", opacity: "0.6" }}>
                          Loading...
                        </h4>
                      </>
                    )}
                  </div>
                  <div className="modal-text-footer">
                    <div className="modal-button">
                      {loaded && (
                        <>
                          <Like materialId={material.id} isMobile={isMobile} />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  return (
    <>
      {showModal && (
        <div className="modal-shade" onClick={handleCloseModal}>
          <div className="material-modal" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <span className="modal-close" onClick={handleCloseModal}>
                {closeIcon}
              </span>
              <div className="modal-image">
                {!imageLoaded && (
                  <div className="image-loader">
                    <div className="loader-spinner"></div>
                  </div>
                )}

                <img
                  src={viewing}
                  onLoad={handleLoaded}
                  data-loading={imageLoaded}
                  alt={material.title}
                />
                {material.type === "cowhide" && (
                  <KindLeatherLogo active={true} />
                )}
                {loaded && (
                  <div className="image-counter">
                    {material.gallery_medium.map((imgCnt, i) =>
                      imageCount <= 1 ? (
                        ""
                      ) : (
                        <button
                          key={i}
                          onClick={() => handleChangeView(imgCnt, i)}
                          style={
                            viewing === imgCnt
                              ? { marginTop: "-10px", borderColor: "#FFFFFF80" }
                              : { marginTop: "0px", borderColor: "#31313180" }
                          }
                          disabled={viewing === imgCnt ? true : false}
                        >
                          <img
                            src={material.tiny_square[i]}
                            alt={material.tiny_square[i]}
                            width="50"
                            height="50"
                          />
                        </button>
                      )
                    )}
                  </div>
                )}
              </div>
              <div className="modal-sidebar">
                <div className="modal-text-body">
                  {loaded ? (
                    <>
                      {material.days_added <= 30 && (
                        <div
                          style={{
                            opacity: "0.6",
                            fontSize: "10px",
                            textTransform: "uppercase",
                            color: "pink",
                          }}
                        >
                          Recently Added
                        </div>
                      )}
                      {material.days_modified <= 30 &&
                      material.days_added >= 30 ? (
                        <div
                          style={{
                            opacity: "0.6",
                            fontSize: "10px",
                            textTransform: "uppercase",
                            color: "#a8cff1",
                          }}
                        >
                          Recently Modified
                        </div>
                      ) : null}
                      <h4
                        style={{ margin: "3px" }}
                        className="linked-item"
                        onClick={() => handleLinkedItem(material.vendor)}
                      >
                        {material.vendor}
                      </h4>
                      <h2>{material.title}</h2>
                      <hr />
                      <h4 style={{ opacity: "0.6", marginBottom: "20px" }}>
                        {material.description}
                      </h4>

                      <div className="table">
                        <div className="column" style={{ width: "40%" }}>
                          <div className="column-header">Pricing</div>
                          <div className="table-content">
                            <h4
                              className="linked-item"
                              onClick={() => handleLinkedItem(material.price)}
                            >
                              ${material.price} / sq.ft.
                            </h4>
                            <div
                              className="price-disclamer"
                              style={{
                                fontSize: "9px",
                                color: "#FEFEFE70",
                                textAlign: "center",
                              }}
                            >
                              Reference Price
                            </div>
                          </div>
                        </div>
                        <div className="column" style={{ width: "60%" }}>
                          <div className="column-header">
                            Material Information
                          </div>
                          <div className="table-content">
                            <h5
                              className="linked-item"
                              onClick={() =>
                                handleLinkedItem(material.substrate)
                              }
                            >
                              <strong>Substrate:</strong> {material.substrate}
                            </h5>
                            <h5
                              className="linked-item"
                              onClick={() => handleLinkedItem(material.type)}
                            >
                              <strong>Type:</strong> {material.type}
                            </h5>
                            <h5
                              className="linked-item"
                              onClick={() => handleLinkedItem(material.subtype)}
                            >
                              <strong>Sub-Type:</strong> {material.subtype}
                            </h5>
                          </div>
                        </div>
                      </div>

                      <div className="table">
                        <div className="column" style={{ width: "50%" }}>
                          <div className="column-header">
                            Material Character
                          </div>
                          <div className="table-content">
                            {material.character[0] &&
                              material.character[0].map((character, i) => (
                                <h5
                                  key={i}
                                  className="linked-item"
                                  onClick={() => handleLinkedItem(character)}
                                >
                                  {character}
                                </h5>
                              ))}
                          </div>
                        </div>
                        <div className="column" style={{ width: "50%" }}>
                          <div className="column-header">Material Finish</div>
                          <div className="table-content">
                            {material.feature[0] &&
                              material.feature[0].map((finish, i) => (
                                <h5
                                  key={i}
                                  className="linked-item"
                                  onClick={() => handleLinkedItem(finish)}
                                >
                                  {finish}
                                </h5>
                              ))}
                          </div>
                        </div>
                      </div>

                      <div className="table">
                        <div className="column" style={{ width: "100%" }}>
                          <div className="column-header">
                            {user[0].userBrand ? user[0].userBrand : "Brand"}{" "}
                            Specific Info
                          </div>
                          <div className="table-content">
                            {/* {material.feature[0] && material.feature[0].map(finish => <h5 key={finish}>{finish}</h5>)} */}

                            {brandData.length !== 0 ? (
                              brandData.map((data) => (
                                <span key={data.pdm_material_number}>
                                  <h6>
                                    {material.title} {data.thickness}{" "}
                                    {data.mcs_number &&
                                      `- MCS#: ${data.mcs_number}`}{" "}
                                    {data.pcx_material_number &&
                                      `- PCX#: ${data.pcx_material_number}`}{" "}
                                    ( {dataState(data.state)} )
                                  </h6>
                                </span>
                              ))
                            ) : (
                              <h6>
                                No
                                {user[0].userBrand
                                  ? user[0].userBrand
                                  : " brand "}
                                specific details for this material
                              </h6>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <h4 style={{ margin: "0", opacity: "0.6" }}>
                        {materialVendor}
                      </h4>
                      <h2>{materialTitle}</h2>
                      <h4 style={{ margin: "0", opacity: "0.6" }}>
                        Loading...
                      </h4>
                    </>
                  )}
                </div>
                <div className="modal-text-footer">
                  <div
                    className="modal-button"
                    style={{ width: "calc(100% - 4px)", marginLeft: "1px" }}
                  >
                    {loaded && (
                      <>
                        <Like materialId={material.id} isMobile={isMobile} />
                      </>
                    )}
                  </div>
                  <div className="modal-button-bottom-row">
                    <div
                      className="modal-button"
                      style={{ width: "50%" }}
                      onClick={startDownload}
                      data-before-content=""
                    >
                      Download Image
                    </div>
                    <div
                      onClick={handleSingle}
                      className="modal-button"
                      style={{ width: "50%" }}
                      data-before-content=""
                    >
                      View Full Details
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MaterialModal;
