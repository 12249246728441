
function DarkSwitch({isDark, changeIsDark}) {

    const lightIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill="none" viewBox="0 0 24 24" stroke="#FEFEFE">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
    </svg>

    const darkIcon = <svg xmlns="http://www.w3.org/2000/svg" height="23" width="23" fill="none" viewBox="0 0 24 24" stroke="#FEFEFE">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
    </svg>


    return (
        <div className="dark-switch">
        <label className="switch">
        <input type="checkbox" onClick={() => changeIsDark(!isDark)} defaultChecked={isDark ? 'checked' : ''}/>
            <span className="slider"></span>
            <span style={{position:'absolute', top:4, left:4}}>{lightIcon}</span>
            <span style={{position:'absolute', top:3, right:3}}>{darkIcon}</span>
      </label>
      </div>
    )
}

export default DarkSwitch
