import { useState, useEffect } from 'react'

import swatchImage0 from '../../images/collectionImage.jpg'
import swatchImage1 from '../../images/collectionImage1.jpg'

function CollectionImage({isDark}) {

    const changeDark = isDark ? '#FEFEFE' : '#313131';
    const changeShadow = !isDark ? '#FEFEFE' : '#313131';
    const changeImage = isDark ? swatchImage0 : swatchImage1;

    const [ picLoaded, setPicLoaded ] = useState(false);

    useEffect(() => {
      const img = new Image();
      img.onload = () => {
        setPicLoaded(true);
      }
      img.src = changeImage;
    },[changeImage])
   
    if(picLoaded){
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 780 312"
        version="1.1"
        viewBox="0 0 780 312"
        xmlSpace="preserve"
        style={{cursor:'default', maxHeight:'330px', border:'thin solid #FEFEFE10', margin:'10px', background:'#FEFEFE05', borderRadius:'12px'}}
      >
        {/* <path  ---- This is the background
          d="M347.7 294.5H18.4c-.5 0-.9-.4-.9-.9V21.4c0-.5.4-.9.9-.9h329.3c.5 0 .9.4.9.9v272.3c-.1.4-.5.8-.9.8z"
          className="st1"
          style={{fill:'#FEFEFE', stroke:'none'}}
        ></path> */}
         <defs>
            <clipPath id="swatch">
                <path style={{fill:'pink'}} d="M17.7 251.4V21c0-.3.2-.5.5-.5h329.9c.3 0 .6.2.6.6v230.4h-331z" />  
            </clipPath>
         </defs>
         <image width="400" height="330" href={changeImage} clipPath="url(#swatch)" />
        <path
          d="M347.7 294.5H18.4c-.5 0-.9-.4-.9-.9V21.4c0-.5.4-.9.9-.9h329.3c.5 0 .9.4.9.9v272.3c-.1.4-.5.8-.9.8z"
          className="st1"
          style={{fill:'none', stroke:'gray'}}
        ></path>
        <text className="st2 st3 st4" transform="translate(120 272)" style={{fontSize:'17px', fontWeight:'bold', fill: changeDark }}>
          MATERIAL NAME
        </text>
        <text className="st6 st7 st8" transform="translate(102.5 287)" style={{fontSize:'12px',fill: changeDark, opacity:'0.7'}}>
          REALLY GOOD DESCRIPTION
        </text>
        <circle cx="135" cy="233" r="8.5" className="st9" style={{fill:'none'}}></circle>
        <path
          d="M135 226c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7m0-3c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10z"
          className="st10"
          style={{fill:'white' , filter:'drop-shadow(0px 0px 2px rgba(0,0,0,0.2))'}}
        ></path>
        <circle cx="159" cy="233" r="8.5" className="st11" style={{fill:'white' , opacity:'0.5'}}></circle>
        <path
          d="M159 226c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7m0-3c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10z"
            style={{fill:'white', filter:'drop-shadow(0px 0px 2px rgba(0,0,0,0.2))'}}
        ></path>
        <circle cx="183" cy="233" r="8.5" className="st11" style={{fill:'white' , opacity:'0.5'}}></circle>
        <path
          d="M183 226c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7m0-3c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10z"
            style={{fill:'white', filter:'drop-shadow(0px 0px 2px rgba(0,0,0,0.2))'}}
        ></path>
        <circle cx="207" cy="233" r="8.5" className="st11" style={{fill:'white' , opacity:'0.5'}}></circle>
        <path
          d="M207 226c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7m0-3c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10z"
             style={{fill:'white', filter:'drop-shadow(0px 0px 2px rgba(0,0,0,0.2))'}}
        ></path>
        <g>
          <circle cx="231" cy="233" r="8.5" className="st11" style={{fill:'white' , opacity:'0.5'}}></circle>
          <path
            d="M231 226c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7m0-3c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10z"
            style={{fill:'white', filter:'drop-shadow(0px 0px 2px rgba(0,0,0,0.2))'}}
          ></path>
        </g>
        <text className="st12 st7 st8" transform="translate(21.781 38.515)" style={{filter:'drop-shadow(0px 0px 3px black)', fill:'#FEFEFE', fontWeight:'bold', fontSize:'14px'}}>
          VENDOR
        </text>
        <path
          d="M343.1 33.3l-4.8-.7-2.1-4.2c-.4-.7-1.5-.8-1.9 0l-2.1 4.2-4.8.7c-.9.1-1.2 1.1-.6 1.7l3.4 3.2-.8 4.6c-.1.8.8 1.4 1.5 1.1l4.3-2.2 4.3 2.2c.8.4 1.7-.2 1.5-1.1l-.8-4.6 3.4-3.2c.7-.6.4-1.6-.5-1.7zm-4.5 4.4l.8 4.4-4.1-2.1-4.1 2.1.8-4.4-3.3-3.1 4.5-.6 2-4 2 4 4.5.6-3.1 3.1z"
          className="st10"
          style={{filter:'drop-shadow(0px 0px 3px black)', fill:'#FEFEFE'}}
        ></path>
        <path
          d="M443.5 89.2c-21.2 1.8-28.1-17.8-34.9-36.7-5.2-14.7-10.2-28.6-21.4-32.4-8-2.7-18.5.1-31.9 8.6-3-8.1-10.9-13.8-20-13.8-11.8 0-21.4 9.6-21.4 21.4s9.6 21.4 21.4 21.4c11.8 0 21.4-9.6 21.4-21.4 0-2.3-.4-4.5-1.1-6.6 13.2-8.5 23.5-11.3 31.2-8.7 10.8 3.7 15.7 17.4 20.8 31.9 6.5 18.4 13.3 37.4 33.1 37.4.9 0 1.8 0 2.7-.1l.1-1zM335.4 56.7c-11.3 0-20.4-9.2-20.4-20.4s9.2-20.4 20.4-20.4c11.3 0 20.4 9.2 20.4 20.4s-9.2 20.4-20.4 20.4z"
          className="st2"
          style={{fill:changeDark, filter:`drop-shadow(0px 0px 2px ${changeShadow})`}}
        ></path>
        <text transform="translate(469.37 89.909)" style={{textTransform:'uppercase', fontSize:'20px', fill: changeDark}}>
          <tspan x="0" y="0" className="st2 st7 st13">
            CLICK ON The STAR TO
          </tspan>
          <tspan x="-22.1" y="24" className="st2 st7 st13">
            ADD TO YOUR COLLECTION
          </tspan>
        </text>
     
        <text transform="translate(421.608 172.426)" style={{textTransform:'uppercase', fontSize:'20px', fill: changeDark}}>
          <tspan x="30" y="0" className="st2 st7 st13">
            Or CLICK this button
          </tspan>
          <tspan x="30" y="24" className="st2 st7 st13">
            in the material pop up
          </tspan>
        </text>

        <path
          d="M722.3 219.8c.9-7.2.7-20.1-8.9-31.1-11.4-13-27.3-13.3-30.4-13.2v1c3-.1 18.5.1 29.6 12.8 9.3 10.5 9.5 23.2 8.7 30.3-1-1-2.5-1.5-3.7-1.9 1.6 1.8 3 3.9 3.5 6.3 1.3-2 3.3-3.6 5.5-4.6-1.3-.2-3.1-.2-4.3.4z"
          className="st2"
          style={{fill:changeDark, filter:`drop-shadow(0px 0px 2px ${changeShadow})`}}
        ></path>
           <g>
          <path d="M413.5 230.5H758.5V274.5H413.5z" className="st14" style={{stroke:'gray', fill:'none'}}></path>
          <text className="st15 st7 st16" transform="translate(479.336 259)" style={{fill:'gray', fontSize:'17px', fontWeight:100}}>
            ADD TO YOUR COLLECTION
          </text>
        </g>
        
      </svg>
    )}
    return (<div style={{minHeight:'420px', maxHeight:'420px', border:'thin solid #FEFEFE10', margin:'10px', background:'#FEFEFE05', borderRadius:'12px', width:'60%'}}></div>)
  }
  
  export default CollectionImage;