import { useEffect, useRef, useState, useContext } from "react";
//import CloseIcon from '../../images/close.svg'
import { Link, useHistory, useLocation } from "react-router-dom";
import Search from "./Search";
import { MatlibContext } from "../../contexts/MatlibContext";
//import EmailNotification from '../EmailNotification'
//import Search from './Search'

function Menu({ menuOpen, setMenuOpen, isMobile, isDark, user }) {
  const { fuzzySort, fuzzyResults } = useContext(MatlibContext);

  const [height, setHeight] = useState("300");
  const elementRef = useRef(null);

  const iconColor = isDark ? "#FEFEFE80" : "#444";

  const history = useHistory();
  const location = useLocation();

  const closeIcon = (
    <div
      className={`close-button ${isDark && "dark"}`}
      onClick={() => setMenuOpen(!menuOpen)}
    >
      <svg
        width="17px"
        height="17px"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        stroke={iconColor}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>{" "}
    </div>
  );

  function handleLogout() {
    setMenuOpen(false);
    history.push("/");
  }

  useEffect(() => {
    if (elementRef.current) {
      setHeight(elementRef.current.clientHeight);
      setMenuOpen(false);
    }
    //setHeight(elementRef.current.clientHeight);
    // eslint-disable-next-line
  }, []);

  const handleSearchButton = () => {
    setMenuOpen(false);
    if (location.pathname !== "/lib") {
      history.push("/lib");
    }
  };

  if (!isMobile) {
    return (
      <div
        className={`menu ${isDark ? "dark" : ""}`}
        style={{ right: menuOpen ? "0px" : `-230px` }}
      >
        {/* <span className="close-button" onClick={() => setMenuOpen(!menuOpen)}><img src={CloseIcon} alt="close"/></span> */}
        {closeIcon}
        <div className="menu-top">
          <Link to="/vendor" onClick={() => setMenuOpen(false)}>
            Vendors
          </Link>
          <Link to="/contact" onClick={() => setMenuOpen(false)}>
            Contact
          </Link>
          {user.role === "vendor" && (
            <Link to="/test" onClick={() => setMenuOpen(false)}>
              Test
            </Link>
          )}
          {/* <Link to="/query" onClick={() => setMenuOpen(false)}>Query</Link> */}
          {/* <Link to="/bd" onClick={() => setMenuOpen(false)}>Backdoor</Link> */}
        </div>
        <div className="menu-bottom">
          <h5
            style={{
              textAlign: "center",
              opacity: "0.6",
              textTransform: "capitalize",
            }}
          >
            {user.userBrand}-{user.userRole}: {user.userName}
          </h5>
          <button onClick={handleLogout}>logout</button>
          {/* <EmailNotification /> */}
          {/* <div className="dark-switch">
            <label className="switch">
              <input type="checkbox" onClick={() => changeIsDark(!isDark)}/>
              <span className="slider"></span>
              <span style={{position:'absolute', top:4, right:4}}>{lightIcon}</span>
              <span style={{position:'absolute', top:3, left:3}}>{darkIcon}</span>
            </label>
            </div> */}
          {/* <button onClick={() => changeIsDark(!isDark)}>{isDark ? <span style={{display: 'flex', width:'120px', justifyContent:'center', height:'20px',lineHeight: '20px'}}>{lightIcon} Light Mode </span>: <span style={{display: 'flex', width: '120px', justifyContent:'center', height: '20px',lineHeight: '20px'}}>{darkIcon} Dark Mode </span>}</button> */}
        </div>
      </div>
    );
  }
  return (
    <div
      className="menu-block"
      style={{ height: menuOpen ? `${height}px` : "0px" }}
    >
      <div
        id="menu"
        ref={elementRef}
        className={`menu ${isDark ? "dark" : ""}`}
        style={{ top: !menuOpen ? `-${height}px` : "0px" }}
      >
        {closeIcon}
        <div className="menu-top">
          <Link to="/library" onClick={() => setMenuOpen(false)}>
            Vendors
          </Link>
          <Link to="/lib" onClick={() => setMenuOpen(false)}>
            Library
          </Link>
          <Link to="/collection" onClick={() => setMenuOpen(false)}>
            Collection
          </Link>
          <Link to="/contact" onClick={() => setMenuOpen(false)}>
            Contact
          </Link>
          <Link to="/" onClick={() => setMenuOpen(false)}>
            Landing
          </Link>
          {/* <Link to="/bd" onClick={() => setMenuOpen(false)}>Backdoor</Link>  */}
        </div>
        <div className="menu-bottom">
          <div className="mobile-search-bar">
            <Search isMobile={isMobile} isDark={isDark} />
            <button
              className={`mobile-search-button ${
                fuzzySort !== "" && "active"
              } ${isDark && "dark"}`}
              onClick={handleSearchButton}
            >
              Go
            </button>
          </div>
          {fuzzySort !== "" && (
            <span className={`mobile-search-results ${isDark && "dark"}`}>
              <h5>
                {fuzzyResults.total} results for <strong>'{fuzzySort}'</strong>
              </h5>
            </span>
          )}
          {/* <button onClick={handleLogout}> <h5 style={{textAlign:'center', opacity:'0.6', textTransform:'capitalize'}}>{user.userBrand}-{user.userRole}: {user.userName}</h5></button> */}
        </div>
      </div>
    </div>
  );
}

export default Menu;
