import { useContext, useState } from 'react'
import Login from '../components/gui/Login';
import '../styles/pages.scss'
import Logo from '../images/matlibLogoWhite.svg'
import Register from '../components/gui/Register';
import ramones from '../images/ramones.jpg'
//import { Link } from 'react-router-dom'
import { UserContext } from '../contexts/UserContext';




function Landing() {

    const { loggedIn, isDark } = useContext(UserContext);

    const [ isRegister, setIsRegister ] = useState(false);

      return (
        <div className="matlib-landing">
            <div className="hero">
                <div className="logo">
                    <img src={Logo} alt="Matlib logo" />
                    <h1>Digital Material Library</h1>
                </div>
                <div className="login">
                    {!isRegister && <Login/> }
                   
                    {!loggedIn && <>  <br/> <Register setIsRegister={setIsRegister}/></>}
                </div>
            </div>
            <div className="landing-info">
                <div className="landing-info-image" style={{backgroundImage:`url(${ramones})`}}>
                <div className="landing-info-text" style={{color: isDark ? '#313131' : '#FEFEFE', backgroundColor: isDark ?  '#FEFEFE60' : '#31313160' , }}>
                    <h2>Inspired and fueled by The Ramones</h2> 
                    <h4>3 Chords, No waiting...</h4>
                    <h4>No gimmicks and blazing fast.</h4>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Landing