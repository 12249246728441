import { Link } from "react-router-dom";


function NotFound() {



    const backIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
</svg>

    return (
        <div className="not-found">
            <div className="not-found-content">
                <div>Ooops, that page can't be found.</div>
                <Link to='/lib'> {backIcon} Back to library</Link>
            </div>
            
        </div>
    )
}

export default NotFound
