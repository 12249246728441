import { useContext } from 'react'
//import CloseIcon from '../../images/close.svg'
import { MatlibContext } from "../../contexts/MatlibContext";
import '../../styles/gui.scss'
import debounce from 'lodash.debounce';

function Filter({isDark}) {

  const iconColor = isDark  ? '#FEFEFE' : '#C4123C'

    const { fuzzySort, changeFuzzySort } = useContext( MatlibContext )

    //const [searchText, setSearchText ] = useState('')

    const SearchIcon = <div className="cancel-button" style={{paddingTop: '2px'}}>
    <svg width="15px" height="15px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" stroke={iconColor}>
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
    </svg> </div>;

   

  //   const UpIcon = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  //   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
  // </svg>

  //   const DownIcon = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  //    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
  // </svg>

    const handleSearch = (e) => {
      changeFuzzySort(e.target.value)
      debounce(handleTest, 300);
    }

    const handleTest = (e) => {
      console.log('searching', e.target.value)
    }
 
    const handleClearSearch = () => {
      //setSearchText('')
      changeFuzzySort('')
    }

    // const debouncedChangeHandler = useCallback(
    //   debounce(handleSearch, 300)
    // , []);

    const CloseIcon = <div className="cancel-button" style={{paddingTop: '2px'}} onClick={handleClearSearch}>
    <svg width="15px" height="15px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" stroke={iconColor}>
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
    </svg> </div>;

    // const handleTriggerQuickFilter = () => {
    //   changeTriggerFilter(!triggerFilter)
    // }

    return (
     
      <>
      <div className={`search-bar active ${isDark ? 'dark' : ''}`}>
            <input type="text" value={fuzzySort ? fuzzySort : ''} onChange={handleSearch} />
            {fuzzySort === '' ? SearchIcon : CloseIcon}
            
        </div>
        {/* <button className="filter-button" onClick={handleTriggerQuickFilter}>{triggerFilter  ? <><h6>Hide Filter Options </h6>{UpIcon}</> : <><h6>More Filter Options </h6>{DownIcon}</>}</button> */}
        </>
    )
}

export default Filter