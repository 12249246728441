import {useState} from 'react'
function KindLeatherLogo() {

    const [active, setActive ] = useState(false);

    return (
        
        <div onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)} className={`kind-leather-logo ${active ? 'active' : ''}`}>
            <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="20" height="20" fill="currentColor" >
            <path d="M0,8c0-0.9,0-1.7,0-2.6c3.9,0.1,8.1-2.8,8.9-5.6c0.9,0,1.9,0,2.8,0c0,0,0,0,0,0C10,4.2,6.1,8,0,8z"/>
            <path d="M0,13.4c0-0.9,0-1.7,0-2.6c5.9,0.1,10.3-3.2,12.6-6.7c0.7-1.1,1.8-2.8,2-4.3c0.9,0,1.8,0,2.7,0
            c-0.2,1.8-1.5,4.1-2.3,5.3C12.3,9.5,7.1,13.4,0,13.4z"/>
            <path d="M30-0.2c0,0.1,0,0.2,0,0.4c-2.4,0.3-1.8,4.5-1.8,7.1c0,7.5,0,15,0,22.5c-8.7,0-17.5,0-26.2,0c0-3.1,0-6.3,0-9.4
            c0.9-0.1,1.7-0.2,2.6-0.3c0,2.4,0,4.8,0,7.1c2.4,0,4.9,0,7.3,0c-0.9-2.4-1.9-4.4-3.7-5.9c-0.6-0.5-1.4-0.9-2.1-1.4
            c0.9-0.4,2.2-0.6,3.2-1c2.5,1.8,4.5,4.8,5.3,8.3c1.3,0,2.6,0,3.9,0c-0.1-2.6-2.1-6-3.3-7.7c-0.3-0.4-2-2.3-2-2.4
            c0.8-0.3,1.4-0.9,2.1-1.4c0.5,0.4,0.9,1,1.3,1.5c1.6,2,3,4.3,3.9,7c0.3,1,0.4,2,0.7,3c1.4,0,2.8,0,4.2,0c-0.6-5-2.6-8.9-5-12.1
            c-0.7-0.7-1.3-1.5-2-2.2c0,0,0,0,0-0.1c0.6-0.5,1.2-1.3,1.6-2c0.2,0.2,0.4,0.4,0.6,0.6c0.5,0.6,1.1,1.2,1.6,1.9
            c1,1.4,1.8,2.8,2.7,4.3c0.3,0.5,0.6,1,0.8,1.6c0,0,0,0,0,0c0-5.7,0-11.3,0-17c-1,0-2.1,0-3.1,0c-0.1,0.6-0.4,1.2-0.6,1.7
            c-0.8,1.9-1.6,3.4-2.7,5c-3.8,5.3-10.1,10-19.2,10c0-0.9,0-1.7,0-2.6c8.5,0.2,14.8-5,18-10.2c1.1-1.8,2.2-4,2.5-6.4
            C23.7-0.2,26.8-0.2,30-0.2z"/>
            </svg>
            <div className={`kind-text ${active ? 'active' : ''}`}>
                <h6>Asiatan</h6>
                <h6>Kind Leather</h6>
            </div>
        </div>
    )
}

export default KindLeatherLogo