import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import "../../styles/gui.scss";

import { UserContext } from "../../contexts/UserContext";

function Register({ setIsRegister }) {
  const history = useHistory();

  const { addUser, loggedIn, changeLoggedIn, isMobile } =
    useContext(UserContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [brand, setBrand] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState(false);
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(false);
  const [register, setRegister] = useState(false);
  const [role, setRole] = useState("designer");

  // useEffect(() => {

  //     setTimeout(() => {
  //       if(loggedIn && hasToken){
  //         history.push(`./lib`);
  //       }
  //     }, 4000)
  //   }, [loggedIn, history, hasToken]);
  const subDomain = window.location.host;
  useEffect(() => {
    console.log("register", subDomain);
    if (subDomain === "vendor.matlib.co") {
      setRole("vendor");
    }
    // eslint-disable-next-line
  }, []);

  const onFormSubmit = (event) => {
    event.preventDefault();
    //const siteUrl = 'http://localhost:8888/Matlib-wp-react';
    const siteUrl = "https://matlib.co";
    setLoading(true);
    if (password === password2) {
      axios
        .post(
          `${siteUrl}/?rest_route=/simple-jwt-login/v1/users&first_name=${name}&user_login=${name}&display_name=${name}&email=${email}&password=${password}`
        )
        .then((res) => {
          setLoading(false);
          if (res.data.success) {
            axios.post(
              `${siteUrl}/wp-json/ml/v1/designer?id=${res.data.id}&role=${role}&brand=${brand}`
            );
          }
          setLoading(false);
        })
        .then((res) => {
          if (!loggedIn) {
            axios
              .post(
                `https://matlib.co/?rest_route=/simple-jwt-login/v1/auth&email=${email}&password=${password}`
              )
              .then((res) => {
                setToken(res.data.data.jwt);
                localStorage.setItem("token", res.data.data.jwt);
                if (res.data.data.jwt) {
                  axios
                    .get(
                      `${siteUrl}/wp-json/ml/v1/user`,
                      {
                        params: {
                          email: email,
                        },
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + token,
                        },
                      }
                    )
                    .then((result) => {
                      addUser(
                        result.data.id,
                        result.data.displayName,
                        result.data.email,
                        result.data.brand,
                        result.data.role
                      );

                      localStorage.setItem("userId", result.data.id);
                      localStorage.setItem("userName", result.data.displayName);
                      localStorage.setItem(
                        "userEmail",
                        btoa(result.data.email)
                      );
                      localStorage.setItem("userBrand", result.data.brand);
                      localStorage.setItem("userRole", result.data.role);
                      changeLoggedIn(true);
                      handleEmail();
                      setTimeout(() => {
                        history.push(`./lib`);
                      }, 1000);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                setError(true);
                setTimeout(() => setError(false), 3000);
              });
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
          setTimeout(() => setError(false), 3000);
        });
    }
  };

  const handleEmail = () => {
    const userName = localStorage.getItem("userName");
    const userRole = localStorage.getItem("userRole");
    const userBrand = localStorage.getItem("userBrand");

    const subject = `Matlib - ${userName} registerd from ${userBrand}`;
    const body = `<h1>${userRole} - ${userName} from ${userBrand}</h1> <p>Has just Registered onto Matlib.</p> <p>Viewing on: ${isMobile} screen</p>`;

    try {
      const res = axios.post(
        `https://matlib.co/wp-json/ml/v1/email?subject=${subject}&body=${body}&sender=${email}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("response", res);
    } catch (err) {
      if (err.response.status === 500) {
        console.log("Oops! There was a problem with the server");
      }
    }
  };

  // const handleLogout = () => {
  //     localStorage.removeItem( 'token' );
  //     localStorage.removeItem( 'userId' );
  //     localStorage.removeItem( 'userName' );
  //     localStorage.removeItem( 'userEmail' );
  //     localStorage.removeItem( 'userBrand' );
  //     localStorage.removeItem( 'userRole' );
  //     changeLoggedIn(false);
  //     window.location.href = `./`;

  // }

  const handleRegister = () => {
    setRegister(true);
    setIsRegister(true);
  };
  const handleCancelRegister = () => {
    setRegister(false);
    setIsRegister(false);
  };

  return (
    <div className="login-template">
      {!register ? (
        <Container className="login-container">
          <Button
            color="secondary"
            fullWidth
            type="submit"
            variant="contained"
            style={{ backgroundColor: "#C4123C", margin: "5px 0" }}
            onClick={handleRegister}
          >
            {subDomain === "designer.matlib.co"
              ? "Designer Sign Up"
              : "Vendor Sign Up"}
          </Button>
          <div className="vendor-link">
            {subDomain === "designer.matlib.co" ? (
              <a href="https://vendor.matlib.co">vendor sign up here</a>
            ) : (
              <a href="https://designer.matlib.co">designer sign up here</a>
            )}
          </div>
        </Container>
      ) : (
        <Container className="login-container">
          <h4>{email ? email.split("@")[0] : ""}</h4>
          <form onSubmit={onFormSubmit}>
            <TextField
              fullWidth
              value={name}
              label="Name"
              name="name"
              size="small"
              variant="outlined"
              autoComplete="false"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ margin: "5px 0", outline: "none" }}
              onChange={(event) => setName(event.target.value)}
            />

            <TextField
              fullWidth
              value={brand}
              label="Brand"
              name="brand"
              size="small"
              variant="outlined"
              autoComplete="false"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ margin: "5px 0", outline: "none" }}
              onChange={(event) => setBrand(event.target.value)}
            />

            <TextField
              fullWidth
              value={email}
              label="Email Address"
              name="email"
              size="small"
              type="email"
              variant="outlined"
              autoComplete="false"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ margin: "5px 0", outline: "none" }}
              onChange={(event) => setEmail(event.target.value)}
            />

            <TextField
              fullWidth
              value={password}
              label="Password"
              name="password"
              size="small"
              type="password"
              variant="outlined"
              autoComplete="false"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ margin: "5px 0", outline: "none" }}
              onChange={(event) => setPassword(event.target.value)}
            />

            <TextField
              fullWidth
              value={password2}
              label="Password Again Please"
              name="password2"
              size="small"
              type="password"
              variant="outlined"
              autoComplete="false"
              color="secondary"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ margin: "5px 0", outline: "none" }}
              onChange={(event) => setPassword2(event.target.value)}
            />

            <Button
              color="secondary"
              fullWidth
              type="submit"
              variant="contained"
              style={{ backgroundColor: "#C4123C", margin: "5px 0" }}
            >
              Register
            </Button>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              style={{
                backgroundColor: "#FEFEFE",
                color: "#222",
                margin: "5px 0",
              }}
              onClick={handleCancelRegister}
            >
              cancel
            </Button>
            {loading && <LinearProgress color="secondary" />}
            {/* <div className="login-error">Something is wrong with your login. Check and try again</div> */}
            {error ? (
              <div className="login-error">
                Something is wrong with your Registration. Please check and try
                again
              </div>
            ) : (
              ""
            )}
          </form>
        </Container>
      )}
    </div>
  );
}

export default Register;
