import { useState, useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { MatlibContext } from "../contexts/MatlibContext";
import { UserContext } from "../contexts/UserContext";
import "../styles/components.scss";
import SimilarMaterials from "../components/SimilarMaterials";
import FuzzySortComponent from "../components/FuzzySortComponent";
import {
  downloadIcon,
  closeTagIcon,
  checkCircleIcon,
  downCircleIcon,
} from "../components/utils/Icons";
import Like from "../components/gui/Like";
import axios from "axios";
import ReactGA from "react-ga";

function Single() {
  var single = useParams();
  const { vendor, material } = single;

  var slug = material.toLowerCase().trim().split(/\s+/).join("-");

  ReactGA.event({
    category: "view-swatch-single",
    action: `${vendor} - ${slug}`,
  });

  const [viewMaterial, setViewMaterial] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [viewing, setViewing] = useState(0);
  const [brandData, setBrandData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [download, setDownload] = useState(null);
  const [imageDownloadProgress, setImageDownloadProgress] = useState("initial");
  const [imageDownloadMessage, setImageDownloadMessage] =
    useState("Download Image");
  const [changePriceStatus, setChangePriceStatus] = useState("");

  const { user, isMobile } = useContext(UserContext);
  const history = useHistory();

  const { materials, storeNike, changeFuzzySort } = useContext(MatlibContext);

  const handleLoaded = () => {
    setImageLoaded(true);
    setDownload(viewMaterial.gallery_large[viewing]);
  };

  const handleLinkedItem = (item) => {
    changeFuzzySort(item);
    history.push("/lib");
  };

  const handleChangeView = (i) => {
    setImageLoaded(false);
    setViewing(i);
  };

  useEffect(() => {
    if (
      imageDownloadProgress === "complete" ||
      imageDownloadProgress === "error"
    ) {
      const interval = setInterval(() => {
        setImageDownloadProgress("initial");
        setImageDownloadMessage("Download Image");
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [imageDownloadProgress]);

  const handleChangePrice = () => {
    if (viewMaterial.price !== 0 && user[0].userRole === "vendor") {
      setChangePriceStatus("sending...");
      axios
        .post(
          `https://matlib.co/wp-json/ml/v1/update-price?id=${viewMaterial.id}&price=${viewMaterial.price}&auth=9909`
        )
        .then((response) => {
          if (response.status === 200) {
            setChangePriceStatus("success!");
          } else {
            setChangePriceStatus("error");
          }
        });
    }
  };

  var downloadedImg;

  function startDownload() {
    try {
      setImageDownloadProgress("start");
      setImageDownloadMessage("Downloading");
      let imageURL = download;
      downloadedImg = new Image();
      downloadedImg.crossOrigin = "https://designer.matlib.co";
      downloadedImg.addEventListener("load", imageReceived, false);
      downloadedImg.src = imageURL;
    } catch (err) {
      setImageDownloadProgress("error");
      setImageDownloadMessage("error downloading!");
    } finally {
      setImageDownloadProgress("complete");
      setImageDownloadMessage("Success!");
    }
  }

  function imageReceived() {
    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d");
    const title = viewMaterial.vendor + " - " + viewMaterial.title;
    canvas.width = downloadedImg.width;
    canvas.height = downloadedImg.height;

    context.clearRect(0, 0, downloadedImg.height, downloadedImg.width);
    context.drawImage(downloadedImg, 0, 0);
    context.font = "bold 16px Helvetica, sans-serif";
    context.fillStyle = "white";
    context.texAlign = "left";
    context.shadowColor = "black";
    context.shadowBlur = 2;
    context.fillText(title.toUpperCase(), 10, 20);

    try {
      var url = canvas
        .toDataURL("image/jpg")
        .replace("image/jpg", "image/octet-stream");
      //var url = canvas.toDataURL("image/png");
      var link = document.createElement("a");
      link.download = `${viewMaterial.vendor}-${viewMaterial.slug}.jpg`;
      link.href = url;
      link.click();
    } catch (err) {
      console.log("Didn't work: " + err);
    }
  }

  useEffect(() => {
    const singleMaterial = materials.filter(
      (mat) => mat.slug === material && mat.vendor.toLowerCase() === vendor
    );
    if (materials.length !== 0 && singleMaterial.length === 0) {
      setErrorMessage(`${slug} not found`);
      setLoaded(false);
    } else if (singleMaterial.length === 1) {
      setViewMaterial(singleMaterial[0]);
      setBrandData(storeNike.filter((item) => item.slug === material));
      setLoaded(true);
    }
    // eslint-disable-next-line
  }, [materials, material, vendor]);

  function dataState(term) {
    switch (term) {
      case "Discover":
        return "Discovery";
      case "Release":
        return "Released";
      default:
        return "Unknown";
    }
  }

  function downloadProgress(status) {
    switch (status) {
      case "initial":
        return downloadIcon;
      case "start":
        return downCircleIcon;
      case "error":
        return closeTagIcon;
      case "complete":
        return checkCircleIcon;
      default:
        return downloadIcon;
    }
  }

  return (
    <div className="single-container">
      {loaded ? (
        <>
          <div className="single-content">
            <div className="single-image-pane">
              <div className="single-image-large">
                {imageLoaded && (
                  <div className="download-button" onClick={startDownload}>
                    <h4>{imageDownloadMessage}</h4>
                    {downloadProgress(imageDownloadProgress)}
                  </div>
                )}
                {!imageLoaded && (
                  <div className="image-loader" style={{ width: "100%" }}>
                    <div className="loader-spinner"></div>
                  </div>
                )}
                <img
                  onLoadStart={() => setImageLoaded(false)}
                  onLoad={handleLoaded}
                  data-loading={imageLoaded}
                  src={viewMaterial.gallery_large[viewing]}
                  alt={slug}
                />
              </div>
              <div className="single-small-swatch-array">
                {viewMaterial.tiny_square.length >= 2 &&
                  viewMaterial.tiny_square.map((image, i) => (
                    <img
                      key={i}
                      src={image}
                      alt={image}
                      onClick={() => handleChangeView(i)}
                    />
                  ))}
              </div>
            </div>
            {/* SINGLE INFO PANE */}
            <div className="single-info-pane">
              {viewMaterial.days_added <= 30 && (
                <div
                  style={{
                    opacity: "0.6",
                    fontSize: "10px",
                    textTransform: "uppercase",
                    color: "#C4123C",
                  }}
                >
                  Recently Added
                </div>
              )}
              {viewMaterial.days_modified <= 30 &&
                viewMaterial.days_added >= 30 && (
                  <div
                    style={{
                      opacity: "0.6",
                      fontSize: "10px",
                      textTransform: "uppercase",
                      color: "#a8cff1",
                    }}
                  >
                    Recently Modified
                  </div>
                )}

              <h4
                style={{ margin: "3px" }}
                className="linked-item"
                onClick={() => handleLinkedItem(viewMaterial.vendor)}
              >
                {viewMaterial.vendor}
              </h4>
              <h2>{viewMaterial.title}</h2>
              <hr />
              <h3
                style={{
                  opacity: "0.6",
                  marginBottom: "20px",
                  marginTop: "10px",
                }}
              >
                {viewMaterial.description}
              </h3>
              {/* price and material info */}
              <div className="table">
                <div className="column" style={{ width: "40%" }}>
                  <div className="column-header">Pricing</div>
                  <div
                    className="table-content"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <h3
                      className="linked-item"
                      style={{
                        textAlign: "center",
                      }}
                      onClick={() => handleLinkedItem(viewMaterial.price)}
                    >
                      ${viewMaterial.price} / sq.ft.
                    </h3>
                    <div
                      className="price-disclamer"
                      style={{
                        fontSize: "9px",
                        marginTop: "2px",
                        textAlign: "center",
                      }}
                    >
                      Reference Price
                    </div>
                  </div>
                </div>
                <div className="column" style={{ width: "60%" }}>
                  <div className="column-header">Material Information</div>
                  <div className="table-content">
                    <h4
                      className="linked-item"
                      onClick={() => handleLinkedItem(viewMaterial.substrate)}
                    >
                      <strong>Substrate:</strong> {viewMaterial.substrate}
                    </h4>
                    <h4
                      className="linked-item"
                      onClick={() => handleLinkedItem(viewMaterial.type)}
                    >
                      <strong>Type:</strong> {viewMaterial.type}
                    </h4>
                    <h4
                      className="linked-item"
                      onClick={() => handleLinkedItem(viewMaterial.subtype)}
                    >
                      <strong>Sub-Type:</strong> {viewMaterial.subtype}
                    </h4>
                  </div>
                </div>
              </div>

              {/* finish and character */}
              <div className="table">
                <div className="column" style={{ width: "50%" }}>
                  <div className="column-header">Material Character</div>
                  <div className="table-content">
                    {viewMaterial.character[0] &&
                      viewMaterial.character[0].map((character, i) => (
                        <h4
                          key={i}
                          className="linked-item"
                          onClick={() => handleLinkedItem(character)}
                        >
                          {character}
                        </h4>
                      ))}
                  </div>
                </div>
                <div className="column" style={{ width: "50%" }}>
                  <div className="column-header">Material Finish</div>
                  <div className="table-content">
                    {viewMaterial.feature[0] &&
                      viewMaterial.feature[0].map((finish, i) => (
                        <h4
                          key={i}
                          className="linked-item"
                          onClick={() => handleLinkedItem(finish)}
                        >
                          {finish}
                        </h4>
                      ))}
                  </div>
                </div>
              </div>
              {/* Performance & Status */}
              <div className="table">
                <div className="column" style={{ width: "50%" }}>
                  <div className="column-header">Material Performance</div>
                  <div className="table-content">
                    <h4> Softness: {viewMaterial.softness}</h4>
                    <h4>
                      {" "}
                      Oil Content:{" "}
                      {viewMaterial.oil
                        .replace(/&lt;/g, "<")
                        .replace(/&gt;/g, ">")}{" "}
                    </h4>
                    <h4> Bally Flex: {viewMaterial.bally}K </h4>
                  </div>
                </div>
                <div className="column" style={{ width: "50%" }}>
                  <div className="column-header">Material Status</div>
                  <div className="table-content">
                    <h4 style={{ textTransform: "capitalize" }}>
                      {viewMaterial.commercial}
                    </h4>
                  </div>
                </div>
              </div>
              {/* Brand DATA */}
              {brandData.length !== 0 && (
                <div className="table">
                  <div className="column" style={{ width: "100%" }}>
                    <div className="column-header">
                      {user[0].userBrand} Specific Info
                    </div>
                    <div className="table-content">
                      {brandData.map((data) => (
                        <span key={data.pdm_material_number}>
                          <h4>
                            {material.title} {data.thickness}{" "}
                            {data.mcs_number && `- MCS#: ${data.mcs_number}`}{" "}
                            {data.pcx_material_number &&
                              `- PCX#: ${data.pcx_material_number}`}{" "}
                            ( {dataState(data.state)} )
                          </h4>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {/* Sustainablity DATA */}
              {viewMaterial.type === "cowhide" && (
                <div className="table">
                  <div className="column" style={{ width: "100%" }}>
                    <div className="column-header">
                      {material.title} Sustainability Info
                    </div>
                    <div className="table-content">
                      <h4>Asiatan Kind Leather</h4>
                      <h4>90% cutting value</h4>
                    </div>
                  </div>
                </div>
              )}
              <div className="single-like">
                {loaded && (
                  <Like materialId={viewMaterial.id} isMobile={isMobile} />
                )}
              </div>
              {/* END */}
              {user[0].userRole === "vendor" &&
                user[0].userName === "Jesse Smith" && (
                  <div className="single-like vendor-price-input">
                    <input
                      type="number"
                      value={viewMaterial.price}
                      onChange={(e) =>
                        setViewMaterial({
                          ...viewMaterial,
                          price: e.target.value,
                        })
                      }
                    />
                    <button onClick={handleChangePrice}>Change Price</button>
                    <div>{changePriceStatus}</div>
                  </div>
                )}
            </div>
          </div>
          <div className="single-similar-divider">
            <h3>Similar Articles</h3>
          </div>

          <SimilarMaterials
            searchTerm={viewMaterial.search}
            current={slug}
            type={viewMaterial.type}
          />
        </>
      ) : (
        <div className="fallback-text">
          {errorMessage === "" ? (
            `Loading ${slug.replace(/-/g, " ")}`
          ) : (
            <FuzzySortComponent slug={slug} />
          )}
        </div>
      )}
    </div>
  );
}

export default Single;
