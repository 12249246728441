import MatlibLogo from '../../images/matlibLogoWhite.svg';
import '../../styles/gui.scss'
import { useHistory } from "react-router-dom";
function Footer() {

    const history = useHistory();

    const handleLanding = () =>{
        history.push("/contact");
    }

    return (
        <>
         {/* <span className="footer-logo"><img src={MatlibLogo} style={{filter: 'drop-shadow( 0 0 2px rgba(255, 255, 255, .7))'}} alt="footer logo"/></span> */}
        
            <img src={MatlibLogo} alt="Matlib Logo" width='100' height="50" />
            <div className="footer-menu">
                <div className="link" onClick={handleLanding}>Contact</div>

            </div>

        </>


    )
}

export default Footer
