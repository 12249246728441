import { useContext, useMemo, useEffect } from "react";
import { MatlibContext } from "../contexts/MatlibContext";
import { UserContext } from "../contexts/UserContext";
import fuzzysort from "fuzzysort";
import Swatch from "../components/Swatch";
import QuickFilter from "../components/gui/QuickFilter";
import LinearProgress from "@material-ui/core/LinearProgress";
import { forceCheck } from "react-lazyload";

function Library() {
  const {
    materials,
    message,
    changeFuzzySort,
    fuzzySort,
    triggerFilter,
    orderBy,
    changeFuzzyResults,
  } = useContext(MatlibContext);
  const { isDark, isMobile } = useContext(UserContext);

  useEffect(() => {
    forceCheck();
  }, [fuzzySort, orderBy]);

  const optionsFuzzy = {
    limit: 100, // don't return more results than you need!
    allowTypo: true, // if you don't care about allowing typos
    threshold: -10000, // don't return bad results
    keys: [
      "title",
      "vendor",
      "description",
      "price",
      "type",
      "subtype",
      "substrate",
      "search",
    ],
  };

  const resultsFuzzy = fuzzysort.go(fuzzySort, materials, optionsFuzzy);

  useEffect(() => {
    changeFuzzyResults(resultsFuzzy);
    return () => {
      changeFuzzyResults("");
    };
    // eslint-disable-next-line
  }, [fuzzySort]);

  const fuzzyList = useMemo(
    () =>
      resultsFuzzy.map((material) => (
        <Swatch key={material.obj.id} material={material.obj} />
      )),
    [resultsFuzzy]
  );

  const materialList = useMemo(
    () =>
      materials
        .sort(function (a, b) {
          if (orderBy === "popular") {
            return b.view_count - a.view_count;
          }
          if (orderBy === "modified") {
            return a.days_modified - b.days_modified;
          }
          if (orderBy === "dateAdded") {
            return a.days_added - b.days_added;
          }
          return a.days_added - b.days_added;
        })
        .map(
          (res) => <Swatch key={res.id} material={res} />
          // eslint-disable-next-line
        ),
    [materials, orderBy]
  );

  if (isMobile) {
    return (
      <div className={`array-container`}>
        {message !== "" && (
          <LinearProgress className={`library-progress`} color="secondary" />
        )}
        {resultsFuzzy.total === 0 && fuzzySort !== "" ? (
          <div className="fallback-text">
            No results for "<span>{fuzzySort}</span>"{" "}
            <h4>Clear the search and try again...</h4>
            <button onClick={() => changeFuzzySort("")}>CLEAR</button>
          </div>
        ) : (
          <>
            {materials.length === 0 && fuzzySort === "" && (
              <div className="fallback-text">Loading Materials</div>
            )}
            <div className="content">
              {resultsFuzzy.total === 0 ? materialList : fuzzyList}
            </div>
          </>
        )}
      </div>
    );
  }
  return (
    <div className={`array-container ${triggerFilter ? "trigger-filter" : ""}`}>
      {message !== "" && (
        <LinearProgress
          className={`library-progress ${
            triggerFilter ? "trigger-filter" : ""
          }`}
          color="secondary"
        />
      )}
      {resultsFuzzy.total === 0 && fuzzySort !== "" ? (
        <div className="fallback-text">
          No results for "<span>{fuzzySort}</span>"{" "}
          <h4>Clear the search and try again...</h4>
          <button onClick={() => changeFuzzySort("")}>CLEAR</button>
        </div>
      ) : (
        <>
          {materials.length === 0 && fuzzySort === "" && (
            <div className="fallback-text">Loading Materials</div>
          )}
          <QuickFilter isDark={isDark} />
          <div className="content">
            {resultsFuzzy.total === 0 ? materialList : fuzzyList}
          </div>
        </>
      )}
    </div>
  );
}

export default Library;
