import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import Landing from "./pages/Landing";
import Library from "./pages/Library";
//import Dashboard from './pages/Dashboard';
import Layout from "./components/Layout";
import Navbar from "./components/gui/Navbar";
import User from "./pages/User";
import MatlibContextProvider from "./contexts/MatlibContext";
import UserContextProvider from "./contexts/UserContext";
import NotFound from "./pages/NotFound";
import Vendor from "./pages/Vendor";
import ScrollToTop from "./components/utils/ScrollToTop";
import Single from "./pages/Single";
import Contact from "./pages/Contact";
//import Edit from "./pages/Edit";
//import InputMaterial from "./pages/InputMaterial";
//import About from "./pages/About";
import Test from "./pages/Test";
import Collection from "./pages/Collection";
import MobileNavbar from "./components/gui/mobile/Navbar";
import MobileLayout from "./components/MobileLayout";
import Query from "./pages/Query";
import { QueryClient, QueryClientProvider } from "react-query";
//import BackDoor from "./pages/BackDoor";
import ReactGA from "react-ga";

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    ReactGA.initialize("UA-208849889-1");
    ReactGA.pageview("/matlib-landing");
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.screen.width <= 760) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isMobile]);

  if (!isMobile) {
    return (
      <>
        <QueryClientProvider client={queryClient} contextSharing={true}>
          <UserContextProvider>
            <MatlibContextProvider>
              <Router>
                <Navbar />
                <ScrollToTop />
                <Layout>
                  <Switch>
                    <Route exact path="/" component={Landing} />
                    <Route path="/lib" component={Library} />
                    <Route path="/collection" component={Collection} />
                    <Route path="/vendor/:slug" component={Vendor} />
                    <Route path="/vendor" component={Vendor} />
                    <Route path="/user" component={User} />
                    <Route path="/contact" component={Contact} />
                    {/* <Route path="/query" component={Query} /> */}
                    {/* <Route path="/bd" component={BackDoor} /> */}
                    <Route path="/test" component={Test} />
                    {/* <Route path="/edit/:vendor/:material" component={Edit} /> */}
                    {/* <Route path="/input" component={InputMaterial} /> */}
                    <Route path="/:vendor/:material" component={Single} />
                    {/* <Route path="/test" component={Library} /> */}
                    <Route path="*" component={NotFound} />
                  </Switch>
                </Layout>
              </Router>
            </MatlibContextProvider>
          </UserContextProvider>
        </QueryClientProvider>
      </>
    );
  }
  return (
    <>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <UserContextProvider>
          <MatlibContextProvider>
            <Router>
              <ScrollToTop />
              <MobileLayout>
                <MobileNavbar />
                <Switch>
                  <Route exact path="/" component={Landing} />
                  <Route path="/lib" component={Library} />
                  <Route path="/contact" component={Contact} />
                  <Route path="/collection" component={Collection} />
                  <Route path="/query" component={Query} />
                  <Route path="*" component={NotFound} />
                </Switch>
              </MobileLayout>
            </Router>
          </MatlibContextProvider>
        </UserContextProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
